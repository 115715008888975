/*---------------------------img_tablet-----------------------------------------*/
/*---------------------------img_decktop-----------------------------------------*/
@font-face {
  font-family: "Corpid";
  src: url("/fonts/corpid/CorpidE1SCd_Regular.eot");
  src: url("/fonts/corpid/CorpidE1SCd_Regular.eot?#iefix") format('embedded-opentype'), url("/fonts/corpid/CorpidE1SCd_Regular.woff") format('woff'), url("/fonts/corpid/CorpidE1SCd_Regular.svg#svgCorpid") format('svg');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Corpid";
  src: url("/fonts/corpid/CorpidE1SCd_Bold.eot");
  src: url("/fonts/corpid/CorpidE1SCd_Bold.eot?#iefix") format('embedded-opentype'), url("/fonts/corpid/CorpidE1SCd_Bold.woff") format('woff'), url("/fonts/corpid/CorpidE1SCd_Bold.svg#svgCorpid") format('svg');
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Corpid";
  src: url("/fonts/corpid/CorpidE1SCd_Light.eot");
  src: url("/fonts/corpid/CorpidE1SCd_Light.eot?#iefix") format('embedded-opentype'), url("/fonts/corpid/CorpidE1SCd_Light.woff") format('woff'), url("/fonts/corpid/CorpidE1SCd_Light.svg#svgCorpid") format('svg');
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "PTRoubleSans";
  src: url("/fonts/pt-rouble-sans/PTRoubleSans.eot");
  src: url("/fonts/pt-rouble-sans/PTRoubleSans.woff2") format('woff2'), url("/fonts/pt-rouble-sans/PTRoubleSans.woff") format('woff'), url("/fonts/pt-rouble-sans/PTRoubleSans.ttf") format('truetype'), url("/fonts/pt-rouble-sans/PTRoubleSans.svg#PTRoubleSans") format('svg'), url("/fonts/pt-rouble-sans/PTRoubleSans.eot?#iefix") format('embedded-opentype');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Life_Lt";
  src: url("/fonts/v_fonts/Life_Lt.ttf") format('truetype');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Life_Rg";
  src: url("/fonts/v_fonts/Life_Rg.ttf") format('truetype');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Life_B";
  src: url("/fonts/v_fonts/Life_Bold.ttf") format('truetype');
  font-style: normal;
  font-weight: 700;
}
.preloader {
  height: 100%;
  width: 100%;
  display: none;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100000;
  -webkit-perspective: 1600px;
  perspective: 1600px;
  -webkit-perspective-origin: 20% 50%;
  perspective-origin: 20% 50%;
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
  opacity: 1;
}
.spinner {
  width: 30px;
  height: 30px;
  border: 2px solid #f3f3f3;
  border-top: 3px solid #002395;
  border-radius: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  -webkit-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
}
.preloader.fade {
  opacity: 0;
}
.b-ico-preloader {
  width: 52px;
  height: 67px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  -webkit-animation: ico 5s infinite linear;
  animation: ico 5s infinite linear;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes ico {
  from {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  to {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}
@keyframes ico {
  from {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  to {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}
.arrow_bordered {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-left: 10px;
  width: 22px;
  height: 17px;
  border-left: 2px solid transparent;
  border-bottom: 2px solid #002395;
  -webkit-transform: rotate(90deg) scale(1.5);
  transform: rotate(90deg) scale(1.5);
}
.arrow_bordered.sm {
  -webkit-transform: rotate(90deg) scale(1);
  transform: rotate(90deg) scale(1);
}
.arrow_bordered,
.arrow_bordered::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-right: 2px solid transparent;
}
.arrow_bordered::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-left: 2px solid #002395;
  border-top: 2px solid #002395;
  border-bottom: 2px solid transparent;
  -webkit-transform: rotate(45deg) skew(10deg, 10deg);
  transform: rotate(45deg) skew(10deg, 10deg);
  left: -1px;
  bottom: -12px;
}
.close-cross {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 32px;
  height: 32px;
}
.close-cross.blue:before,
.close-cross.blue:after {
  background-color: #002395;
}
.close-cross:before,
.close-cross:after {
  position: absolute;
  content: ' ';
  height: 48px;
  width: 2px;
  background-color: #EDE93A;
}
.close-cross:before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.close-cross:after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
/*-----------------------------Mixin---------------------------------*/
/*-----------------------------Reset----------------------------------------*/
html,
body {
  border: 0;
  font-family: "Life_Lt", "Helvetica", Arial, sans-serif;
  line-height: 1.5;
  margin: 0;
  padding: 0;
}
div,
span,
object,
iframe,
img,
table,
caption,
thead,
tbody,
tfoot,
tr,
tr,
td,
article,
aside,
canvas,
details,
figure,
hgroup,
menu,
nav,
footer,
header,
section,
summary,
mark,
audio,
video {
  border: 0;
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
address,
cit,
code,
del,
dfn,
em,
ins,
q,
samp,
small,
strong,
sub,
sup,
b,
i,
hr,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
legend,
label {
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
}
ol,
ul {
  list-style: none;
}
article,
aside,
canvas,
figure,
figure img,
figcaption,
hgroup,
footer,
header,
nav,
section,
audio,
video {
  display: block;
}
table {
  border-collapse: separate;
  border-spacing: 0;
}
table caption,
table th,
table td {
  text-align: left;
  vertical-align: middle;
}
a img {
  border: 0;
}
:focus {
  outline: 0 !important;
}
a:hover {
  text-decoration: none;
}
input,
input:focus,
input:active,
button,
button:focus,
button:active {
  outline: none;
}
/*-----------------------------Header---------------------------------------*/
body.popup-displayed {
  height: 100vh;
  overflow: hidden;
  position: relative;
}
.header {
  position: static;
  width: 100%;
  z-index: 999;
  left: 0;
  right: 0;
}
.header_menu {
  height: 100% !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: fixed;
  right: 0px;
  top: 0;
  width: 550px;
  z-index: 9999;
  overflow-y: scroll;
  background-color: white;
  -webkit-transform: translate(550px, 0px);
  transform: translate(550px, 0px);
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}
.header_menu a,
.header_menu label {
  color: #002395;
  font-size: 32px;
  padding-left: 7px;
  font-family: "Life_Rg";
}
.header_menu a:hover,
.header_menu label:hover,
.header_menu a:active,
.header_menu label:active {
  text-decoration: none;
}
.header_menu .btn_lang {
  position: relative;
  padding-right: 40px;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}
.header_menu .btn_lang i {
  background: url("/images/newimg/rectangle.png") no-repeat center center;
  width: 21px;
  height: 14px;
  position: absolute;
  top: 18px;
  right: 0;
}
.header_menu .btn_lang .transform_i {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.header_menu ul {
  margin-top: 24px;
  float: left;
  width: 100%;
}
.header_menu ul li + li {
  margin-top: 12px;
}
.header_menu ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 40px;
}
.header_menu ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  line-height: 68px;
}
.header_menu ul li a .sm {
  -webkit-transform: rotate(90deg) scale(1.5);
  transform: rotate(90deg) scale(1.5);
}
.header_menu ul li:hover {
  background-color: #002395;
}
.header_menu ul li:hover a {
  color: white;
}
.header_menu ul li:hover .arrow_bordered {
  border-bottom: 2px solid white;
}
.header_menu ul li:hover .arrow_bordered::before {
  border-left: 2px solid white;
  border-top: 2px solid white;
}
.header_menu ul li:last-child a:after {
  background: none;
}
.header_menu .header_popup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 108px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header_menu .header_popup a {
  cursor: pointer;
}
.header_menu .header_popup .header_btn {
  margin-right: 6px;
}
.header_menu .header_popup .header_btn button {
  background-color: transparent;
  border-color: transparent;
  opacity: 1;
}
.header_menu .header_popup .header_btn button:last-child {
  background-color: #bf0000;
}
.header_menu .header_popup .header_btn button:last-child i {
  background: url("/images/newimg/close.png") no-repeat center center;
}
.header_menu #toggle {
  background-color: #424242;
}
.header_menu #toggle .radio_sprite {
  display: none;
}
.header_menu #toggle .radio_sprite:checked + label::before {
  background-position: 0 -61px;
}
.header_menu #toggle label {
  cursor: pointer;
  background-position: 0 10px;
  line-height: 31px;
  font-weight: 400;
  margin-left: 15px;
}
.header_menu #toggle label:before {
  content: "";
  display: inline-block;
  float: left;
  height: 31px;
  width: 45px;
  background: url("/images/newimg/radio.png") no-repeat;
  background-position: 0 0px;
}
.header_menu form {
  width: 100%;
  float: left;
  margin: 76px 0;
}
.header_menu form .col-xs-12 + .col-xs-12 {
  margin-top: 50px;
}
.header_menu.in {
  -webkit-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}
.header_menu + .container > .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px 0;
  margin-bottom: -1px;
}
.header_menu + .container-fluid > .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px 0;
  margin-bottom: -1px;
}
.header_logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 20px;
}
.header a {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.header_btn {
  margin: 3px 20px 0 0;
}
.header_btn_search {
  width: 68px;
  height: 68px;
  border-color: transparent;
  position: relative;
  outline: none;
  background-color: transparent;
}
.header_btn_search i {
  background: url("/images/newimg/search_tablet.svg") no-repeat center center;
  background-size: 50px;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.header_btn_user {
  width: 68px;
  height: 68px;
  border-color: transparent;
  position: relative;
  outline: none;
  background-color: transparent;
  margin-left: 6px;
}
.header_btn_user i {
  background: url("/images/newimg/admin_tablet.svg") no-repeat center center;
  background-size: 50px;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.header_btn_nav {
  width: 68px;
  height: 68px;
  border-color: transparent;
  position: relative;
  outline: none;
  background-color: transparent;
  margin-left: 6px;
}
.header_btn_nav i {
  width: 40px;
  height: 40px;
  background: none;
  position: absolute;
  left: 10px;
  top: 8px;
  display: block;
  border-bottom: 2px solid #002395;
}
.header_btn_nav i::before,
.header_btn_nav i::after {
  position: absolute;
  content: ' ';
  width: 40px;
  left: 0;
  height: 2px;
  background-color: #002395;
}
.header_btn_nav i::before {
  top: 25px;
}
.header_btn_nav i::after {
  top: 12px;
}
.header_btn_up {
  width: 68px;
  height: 68px;
  border-color: transparent;
  position: relative;
  outline: none;
  background-color: transparent;
}
.header_btn_up i {
  background: url("/images/newimg/chevron_up.png") no-repeat center center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.header_btnnav {
  margin: 3px 20px 0 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.header_btnnav_search {
  width: 68px;
  height: 68px;
  border-color: transparent;
  position: relative;
  outline: none;
  background-color: transparent;
}
.header_btnnav_search i {
  background: url("/images/newimg/search_tablet.svg") no-repeat center center;
  background-size: 45px;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.header_btnnav_user {
  width: 68px;
  height: 68px;
  border-color: transparent;
  position: relative;
  outline: none;
  background-color: transparent;
  margin-left: 6px;
}
.header_btnnav_user i {
  background: url("/images/newimg/admin_tablet.svg") no-repeat center center;
  background-size: 45px;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.header_btnnav_nav {
  width: 68px;
  height: 68px;
  border-color: transparent;
  position: relative;
  outline: none;
  background-color: transparent;
  margin-left: 6px;
  background-color: #002395;
}
.header_btnnav_nav i {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.header_btnnav_up {
  width: 68px;
  height: 68px;
  border-color: transparent;
  position: relative;
  outline: none;
  background-color: transparent;
}
.header_btnnav_up i {
  background: url("/images/newimg/chevron_up.png") no-repeat center center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.wrapper {
  max-width: 1280px;
  margin: 0 auto;
  min-width: 320px;
}
/*--------------------------------------------Footer-----------------------------------------------*/
.footer {
  background-color: #DADADA;
  width: 100%;
  height: 100%;
  float: left;
  padding-top: 45px;
}
.footer a {
  font-family: Life_Rg;
  color: #002395;
  text-decoration: none;
}
.footer_soc {
  width: 340px;
  margin: 82px auto 62px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.footer_soc_1 {
  display: inline-block;
  width: 53px;
  height: 53px;
  position: relative;
}
.footer_soc_1 i {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: url("/images/newimg/Social_Facebook.png") no-repeat center center;
}
.footer_soc_2 {
  display: inline-block;
  width: 53px;
  height: 53px;
  position: relative;
}
.footer_soc_2 i {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: url("/images/newimg/Social_Twitter.png") no-repeat center center;
}
.footer_soc_3 {
  display: inline-block;
  width: 53px;
  height: 53px;
  position: relative;
}
.footer_soc_3 i {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: url("/images/newimg/Social_YouTube.png") no-repeat center center;
}
.footer_soc_4 {
  display: inline-block;
  width: 53px;
  height: 53px;
  position: relative;
}
.footer_soc_4 i {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: url("/images/newimg/Social_GooglePlus.png") no-repeat center center;
}
.footer span {
  color: #002395;
  font: 400 32px/40px "Life_Lt";
  display: block;
  text-align: center;
  margin-bottom: 62px;
  margin: 0 auto 58px;
  width: 555px;
  letter-spacing: 0px;
}
.footer .footer_btn {
  padding: 0 5px;
}
.footer .footer_btn a {
  font-size: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 13px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  border-top: 2px solid #002395;
  text-indent: 15px;
  color: #002395;
}
.footer .footer_btn a:hover {
  text-decoration: none;
}
.footer .copyright span {
  margin-top: 70px;
  text-align: left;
  margin-left: 7px;
  font-family: Life_Rg;
}
/*---------------------------------------------Home-------------------------------------------------*/
.content {
  color: #333;
}
.home_banner_container {
  height: 700px;
}
.home_banner_slide {
  height: 700px;
  background-size: cover;
  position: relative;
}
.home_banner_slide:first-child {
  background: url("/images/newimg/img_tmp/Banner.jpg") no-repeat center center;
}
.home_banner_slide_btn {
  background-color: #002395;
  border-color: #002395;
  color: #fff;
  width: 352px;
  height: 90px;
  font-size: 36px;
  position: absolute;
  top: 68.5%;
  left: 50%;
  margin-left: -176px;
}
.home_banner_slide h2 {
  position: absolute;
  top: 33%;
  left: 50%;
  margin-left: -300px;
  color: #fff;
  font-size: 54px;
  letter-spacing: -1px;
  line-height: 60px;
  text-align: center;
  width: 600px;
  max-height: 200px;
  overflow: hidden;
}
.home_banner_slide h2 span {
  font-size: 72px;
  letter-spacing: 1.4px;
  line-height: 71px;
  display: block;
}
.home_menu {
  background-color: #002395;
  width: 100%;
  height: 261px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-top: 16px solid #002395;
}
.home_menu ul {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.home_menu ul li {
  height: 245px;
  width: 160px;
  text-align: center;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  position: relative;
}
.home_menu ul li a {
  display: block;
  font-size: 30px;
  line-height: 325px;
  color: #fff;
  text-decoration: none;
  margin: 0 2px;
  white-space: nowrap;
  height: 100%;
  position: relative;
  /*                &:active{
                                    background-color: @bg_home_menu_hover;
                                }*/
}
.home_menu ul li .home_menu_icon_1:before {
  content: "";
  background: url("/images/newimg/play-circle.svg") no-repeat center center;
  width: 86px;
  height: 86px;
  position: absolute;
  left: 50%;
  margin-left: -43px;
  top: 15%;
}
.home_menu ul li .home_menu_icon_2:before {
  content: "";
  background: url("/images/newimg/reports.svg") no-repeat center center;
  width: 86px;
  height: 86px;
  position: absolute;
  left: 50%;
  margin-left: -43px;
  top: 15%;
}
.home_menu ul li .home_menu_icon_3:before {
  content: "";
  background: url("/images/newimg/accessories.svg") no-repeat center center;
  width: 86px;
  height: 86px;
  position: absolute;
  left: 50%;
  margin-left: -43px;
  top: 15%;
}
.home_menu ul li .home_menu_icon_4:before {
  content: "";
  background: url("/images/newimg/apps.svg") no-repeat center center;
  width: 86px;
  height: 86px;
  position: absolute;
  left: 50%;
  margin-left: -43px;
  top: 15%;
}
.home_menu ul li .active.home_menu_icon_1:before {
  background-image: url("/images/newimg/play-circle_hover.svg");
}
.home_menu ul li .active.home_menu_icon_2:before {
  background-image: url("/images/newimg/reports_hover.svg");
}
.home_menu ul li .active.home_menu_icon_3:before {
  background-image: url("/images/newimg/accessories_hover.svg");
}
.home_menu ul li .active.home_menu_icon_4:before {
  background-image: url("/images/newimg/apps_hover.svg");
}
.home_menu ul .active {
  background-color: #ffff00;
  color: #002395;
}
.txt_slider {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 36px 0 41px;
}
.txt_slider h3 {
  font-size: 48px;
  letter-spacing: -0.9px;
  padding-left: 2px;
}
.txt_slider a {
  font-size: 32px;
  text-decoration: underline;
  color: #333;
  padding: 8px 6px 0 0;
  letter-spacing: -0.5px;
}
.bg_block {
  background-color: #f4f4f4;
}
.slider_1 .slick-slide {
  max-width: 604px;
  padding-left: 24px;
}
.slider_1 .item {
  margin-left: 20px;
  width: 228px;
}
.slider_1 a:hover,
.slider_1 a:focus {
  color: transparent;
  text-decoration: none;
}
.slider_1 a img {
  width: 580px;
  height: 337px;
}
.slider_1 a h4 {
  font: 32px "Life_Rg";
  color: #333;
  margin: 22px 0 51px;
}
.slider_1 a:hover {
  text-decoration: none;
}
.slider_2 .slick-list {
  padding-left: 21px;
  padding-top: 20px;
  margin-top: -20px;
}
.slider_2 .owl-stage-outer {
  padding-top: 20px;
  margin-top: -13px;
}
.slider_2 .cartoons_slide {
  width: 330px;
  height: 360px;
  background-color: #fff;
  position: relative;
  margin-bottom: 65px;
  margin-left: 20px;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.slider_2 .cartoons_slide img {
  width: 330px;
  height: 222px;
}
.slider_2 .cartoons_slide h4 {
  font-size: 30px;
  display: inline-block;
  max-height: 85px;
  padding: 13px 30px 0 18px;
  color: #333;
  overflow: hidden;
  position: relative;
}
.slider_2 .cartoons_slide h4 i {
  background: url("/images/newimg/Chevron_left.png") no-repeat center center;
  width: 11px;
  padding: 20px;
  padding-top: 16px;
  position: relative;
  z-index: 1;
}
.slider_2 .cartoons_slide span {
  font: 24px "Life_Rg";
  display: block;
  letter-spacing: -0.3px;
  max-height: 30px;
  padding: 0 18px 13px 18px;
  color: #333;
  overflow: hidden;
}
.slider_2 .cartoons_slide .label_text,
.slider_2 .cartoons_slide .label_text_free {
  margin: 3px 14px;
  font: 29px "Life_B";
  color: white;
  position: absolute;
}
.slider_2 .cartoons_slide .label_text_free {
  margin: 3px 2px;
}
.slider_2 .books_slide {
  width: 210px;
  height: 360px;
  margin-left: 20px;
  margin-bottom: 65px;
  position: relative;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.slider_2 .books_slide img {
  width: 210px;
  height: 228px;
}
.slider_2 .books_slide h4 {
  font-size: 30px;
  display: inline-block;
  max-height: 85px;
  padding: 13px 30px 0 18px;
  color: #333;
  overflow: hidden;
  position: relative;
}
.slider_2 .books_slide h4 i {
  background: url("/images/newimg/Chevron_left.png") no-repeat center center;
  width: 11px;
  padding: 20px;
  padding-top: 16px;
  position: relative;
  z-index: 1;
}
.slider_2 .books_slide span {
  font: 24px "Life_Rg";
  display: block;
  letter-spacing: -0.3px;
  max-height: 30px;
  padding: 0 18px 13px 18px;
  color: #333;
  overflow: hidden;
}
.slider_2 .books_slide .label_text,
.slider_2 .books_slide .label_text_free {
  margin: 3px 14px;
  font: 29px "Life_B";
  color: white;
  position: absolute;
}
.slider_2 .books_slide .label_text_free {
  margin: 3px 2px;
}
.slider_2 .audiobooks_slide.game_slide,
.slider_2 .games_slide.game_slide {
  height: 388px;
}
.slider_2 .audiobooks_slide.game_slide img,
.slider_2 .games_slide.game_slide img {
  height: 250px;
}
.slider_2 .books_slide.game_slide {
  height: 388px;
}
.slider_2 .books_slide.game_slide img {
  height: 290px;
}
.slider_2 .books_slide {
  height: 388px;
}
.slider_2 .books_slide img {
  height: 290px;
}
.slider_2 .audiobooks_slide {
  width: 210px;
  height: 360px;
  margin-left: 20px;
  margin-bottom: 65px;
  position: relative;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  height: 388px;
}
.slider_2 .audiobooks_slide img {
  width: 210px;
  height: 228px;
}
.slider_2 .audiobooks_slide h4 {
  font-size: 30px;
  display: inline-block;
  max-height: 85px;
  padding: 13px 30px 0 18px;
  color: #333;
  overflow: hidden;
  position: relative;
}
.slider_2 .audiobooks_slide h4 i {
  background: url("/images/newimg/Chevron_left.png") no-repeat center center;
  width: 11px;
  padding: 20px;
  padding-top: 16px;
  position: relative;
  z-index: 1;
}
.slider_2 .audiobooks_slide span {
  font: 24px "Life_Rg";
  display: block;
  letter-spacing: -0.3px;
  max-height: 30px;
  padding: 0 18px 13px 18px;
  color: #333;
  overflow: hidden;
}
.slider_2 .audiobooks_slide .label_text,
.slider_2 .audiobooks_slide .label_text_free {
  margin: 3px 14px;
  font: 29px "Life_B";
  color: white;
  position: absolute;
}
.slider_2 .audiobooks_slide .label_text_free {
  margin: 3px 2px;
}
.slider_2 .audiobooks_slide img {
  height: 290px;
}
.slider_2 .audiobooks_slide img {
  height: 250px;
}
.slider_2 .games_slide {
  width: 210px;
  height: 360px;
  margin-left: 20px;
  margin-bottom: 65px;
  position: relative;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  height: 388px;
}
.slider_2 .games_slide img {
  width: 210px;
  height: 228px;
}
.slider_2 .games_slide h4 {
  font-size: 30px;
  display: inline-block;
  max-height: 85px;
  padding: 13px 30px 0 18px;
  color: #333;
  overflow: hidden;
  position: relative;
}
.slider_2 .games_slide h4 i {
  background: url("/images/newimg/Chevron_left.png") no-repeat center center;
  width: 11px;
  padding: 20px;
  padding-top: 16px;
  position: relative;
  z-index: 1;
}
.slider_2 .games_slide span {
  font: 24px "Life_Rg";
  display: block;
  letter-spacing: -0.3px;
  max-height: 30px;
  padding: 0 18px 13px 18px;
  color: #333;
  overflow: hidden;
}
.slider_2 .games_slide .label_text,
.slider_2 .games_slide .label_text_free {
  margin: 3px 14px;
  font: 29px "Life_B";
  color: white;
  position: absolute;
}
.slider_2 .games_slide .label_text_free {
  margin: 3px 2px;
}
.slider_2 .games_slide img {
  height: 290px;
}
.slider_2 .games_slide img {
  height: 250px;
}
.label_box,
.label_box_free {
  position: absolute;
  background: url("/images/newimg/ribbon_hit.png") no-repeat center center;
  width: 105px;
  height: 56px;
  left: -15px;
  top: -12px;
}
.label_box.list,
.label_box_free.list {
  background-size: 70%;
  left: -25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.label_box.list .label_text,
.label_box_free.list .label_text,
.label_box.list .label_text_free,
.label_box_free.list .label_text_free {
  max-height: 16px;
  margin: 12px 34px;
  font: 19px "Life_B";
  color: white;
}
.label_box.list.top,
.label_box_free.list.top {
  top: 25px;
}
.label_box.top,
.label_box_free.top {
  top: 50px;
}
.label_box_free {
  background: url("/images/newimg/ribbon_free.png") no-repeat center center;
  width: 196px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.label_box_free.list {
  left: -40px;
}
/*------------------------------------------slick_home---------------------------------------------------*/
.slick-dots {
  bottom: 50px;
}
.slick-dots li {
  background-color: #fff;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin: 0 15px;
}
.slick-dots .slick-active {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 24px;
  width: 24px;
  border: 4px solid #fff;
  background-color: #002395;
  margin: 0 10px;
}
.slick-dotted.slick-slider {
  margin-bottom: 0;
}
.home_banner_slide:not(:first-child) {
  display: none;
}
.slick-initialized .home_banner_slide {
  display: block;
}
/*------------------------------------------owl_home---------------------------------------------------*/
.owl-carousel {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
.owl-dots {
  bottom: 50px;
  position: absolute;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.owl-dots .owl-dot {
  background-color: #fff;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin: 0 15px;
}
.owl-dots .owl-dot.active {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 24px;
  width: 24px;
  border: 4px solid #fff;
  background-color: #002395;
  margin: 0 10px;
}
/*-----------------------------------------------cabinet-----------------------------------------------------*/
.cabinet {
  font-family: "Life_Rg";
  font-size: 32px;
}
.category_title {
  height: auto;
  padding: 60px 0;
  background-color: #002395;
}
.category_title h2 {
  text-align: center;
  color: #fff;
  font: 60px/58px "Life_Lt";
}
.breadcrumbs {
  padding: 26px 28px;
  border-bottom: 2px solid #ECEBEB;
  white-space: nowrap;
  overflow-y: hidden;
  background-color: white;
  font-family: "Life_Rg";
  font-size: 32px;
}
.breadcrumbs a {
  color: #333;
  text-decoration: underline;
}
.breadcrumbs i {
  background: url("/images/newimg/Chevron_down_black.png") no-repeat center center;
  width: 16px;
  height: 30px;
  display: inline-block;
  vertical-align: -7px;
  margin: 0 12px;
}
.cabinet_content {
  padding: 0 20px;
  background-color: #f4f4f4;
}
.cabinet_content .tel {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 30px;
  color: #999;
  margin: 28px 0px;
}
.cabinet_content .tel span:last-child {
  letter-spacing: -0.5px;
}
.cabinet_content .sign_out_account {
  width: 100%;
  height: 90px;
  color: #FBEF3C;
  border: transparent;
  font-size: 36px;
  display: inline-block;
  text-align: center;
  line-height: 80px;
  background-color: #999;
  margin: 20px 0 176px 0px;
}
.ac-container {
  position: relative;
  min-height: 121.5px;
  width: 100%;
  background-color: #fff;
  -webkit-box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.2);
  float: left;
}
.ac-container:before {
  content: "";
  width: 19px;
  height: 35px;
  background: url("/images/newimg/Chevron_down_red.png") no-repeat center center;
  position: absolute;
  right: 42px;
  top: 43px;
}
.ac-container a,
.ac-container span {
  font-size: 36px;
  color: #333;
  display: block;
  width: 100%;
  line-height: 121.5px;
  padding-left: 37px;
}
.ac-container a:hover,
.ac-container span:hover,
.ac-container a:active,
.ac-container span:active {
  text-decoration: none;
}
.ac-container + .ac-container {
  margin-top: 20px;
}
/*--------------------------------------cabinet_page_2--------------------------------------------*/
.cabinet_subscription_management {
  background-color: #ebebeb;
}
.subscription_management {
  position: relative;
  width: 100%;
  -webkit-box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.2);
  margin: 70px 0;
}
.subscription_management span {
  color: #002395;
}
.subscription_management_header {
  background-color: #fff;
}
.subscription_management_header h3 {
  font-size: 62px;
  font-family: "Life_Lt";
  line-height: 72px;
  text-align: center;
  padding: 52px 0 45px;
}
.subscription_management_helper {
  padding: 30px;
  font-size: 36px;
}
.subscription_management_helper p:first-child {
  margin: 0 0 40px;
}
.subscription_management_content {
  background-color: #f4f4f4;
  padding: 42px 25px 28px 30px;
}
.subscription_management_content p {
  font-size: 36px;
  line-height: 46px;
}
.subscription_management_content p + p {
  margin-top: 45px;
}
.subscription_management_access {
  text-align: center;
  background-color: #fff;
  padding: 55px;
}
.subscription_management_access .access_title {
  font-size: 62px;
}
.subscription_management_access .access_title span {
  color: #002395;
}
.subscription_management_footer {
  text-align: center;
  background-color: #fff;
}
.subscription_management_footer .btn_deactivate {
  width: 100%;
  height: 90px;
  color: #FBEF3C;
  border: transparent;
  font-size: 36px;
  display: inline-block;
  text-align: center;
  line-height: 80px;
  background-color: #666;
  margin: 55px 0 50px;
  width: 556px;
}
.subscription_management .label_manage {
  width: 198px;
  height: 72px;
  background: url("/images/newimg/ribbon.png") no-repeat center center;
  position: absolute;
  left: -16px;
  top: -30px;
}
/*--------------------------------------cabinet_page_3--------------------------------------------*/
.history {
  padding: 15px 20px 67px;
}
.history-empty {
  padding: 50px;
  text-align: center;
}
.ac-collapse::before {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.ac-collapse .collapse {
  /*padding: 0 40px;*/
}
.ac-collapse .collapse .box_info {
  border-top: 1px solid #ccc;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 128px;
}
.ac-collapse .collapse .box_info_img {
  width: 36px;
  text-align: center;
  margin-left: -2px;
}
.ac-collapse .collapse .box_info_name {
  padding: 33px 10px 33px 37px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.ac-collapse .collapse .box_info_name h4 {
  font-size: 32px;
  margin-top: -12px;
  margin-bottom: 5px;
}
.ac-collapse .collapse .box_info_name span {
  font: 400 24px "Life_Rg";
  padding: 0;
}
.ac-collapse .collapse .box_info_time {
  width: 75px;
}
.ac-collapse .collapse .box_info_time span {
  padding: 0;
  color: #726E6D;
  font-size: 32px;
}
.ac-collapse.transform_b::before {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.ac-collapse.transform_b span {
  font-family: "Life_B";
}
/*------------------------------------cartoons_PAGE-------------------------------------------------*/
.cartoons {
  font-family: "Life_Rg";
  font-size: 32px;
}
.cartoons_content {
  padding: 0 20px;
  background-color: #f4f4f4;
  padding: 20px 20px 50px;
}
.cartoons_content .cartoons_img {
  width: 600px;
  height: auto;
  position: relative;
  margin: 0 auto;
}
.cartoons_content .cartoons_img img {
  width: 100%;
  height: 348px;
}
.cartoons_content .cartoons_img.unauthorized:after {
  content: "";
  background: url("/images/newimg/Play-arrow-overlay.png") no-repeat center center;
  width: 98px;
  height: 98px;
  position: absolute;
  z-index: 999;
  top: 68px;
  margin-left: -49px;
  background: url("/images/newimg/Play-arrow-overlay_c.png") no-repeat center center;
  width: 96px;
  height: 96px;
  left: 50%;
  margin-left: -48px;
  top: 50%;
  margin-top: -48px;
  z-index: 99;
}
.cartoons_content .cartoons_img .unauthorized:after {
  content: "";
  background: url("/images/newimg/Play-arrow-overlay.png") no-repeat center center;
  width: 98px;
  height: 98px;
  position: absolute;
  z-index: 999;
  top: 68px;
  margin-left: -49px;
  background: url("/images/newimg/Play-arrow-overlay_c.png") no-repeat center center;
  width: 96px;
  height: 96px;
  left: 50%;
  margin-left: -48px;
  top: 50%;
  margin-top: -48px;
  z-index: 99;
}
.cartoons_content h2 {
  font: 48px/48px "Life_Lt";
  margin: 31px 0 23px;
}
.cartoons_content p {
  font-size: 30px;
  margin-bottom: 24px;
}
.cartoons_content .info_content span {
  display: block;
  line-height: 36px;
  font-size: 30px;
  font-family: "Life_Lt";
}
.cartoons_content .ac-container.ac-collapse {
  margin-top: 46px;
}
.cartoons_content .ac-container.ac-collapse p {
  font-size: 32px;
  line-height: 46px;
  padding: 16px 0 14px;
  margin: 0 22px 22px;
}
.cartoons_content .block_loading {
  text-align: center;
  float: left;
  margin: 68px 0px 57px;
}
.cartoons_content .block_loading span {
  text-decoration: underline;
}
.cartoons_content .block_loading .show_more {
  width: 100%;
  height: 90px;
  color: #FBEF3C;
  border: transparent;
  font-size: 36px;
  display: inline-block;
  text-align: center;
  line-height: 80px;
  background-color: #002395;
  width: 556px;
  margin-top: 18px;
}
.line_top_ac {
  border-top: 1px solid #ccc;
}
.collapse.filter_ac {
  padding: 0;
}
.collapse.filter_ac a {
  border-top: 1px solid #ccc;
  background-color: #f4f4f4;
  line-height: 119.5px;
}
.block_img_txt {
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 222px;
  margin-top: 20px;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  float: left;
  position: relative;
}
.block_img_txt .right {
  width: 100%;
}
.block_img_txt.nothing_find {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.block_img_txt .left_img {
  width: 330px;
  height: 222px;
}
.block_img_txt .left_img img {
  height: 100%;
}
.block_img_txt .left_img.video {
  min-width: 330px;
  width: initial !important;
}
.block_img_txt .left_img.video img {
  width: 100%;
}
.block_img_txt .left_img.book {
  min-width: 180px;
  width: initial;
}
.block_img_txt .left_img.audiobook {
  min-width: 230px;
  width: initial;
}
.block_img_txt .right_txt {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 9px 15px;
}
.block_img_txt .right_txt h4 {
  font-size: 30px;
  display: inline-block;
  max-height: 85px;
  padding: 13px 30px 0 18px;
  color: #333;
  overflow: hidden;
  position: relative;
  font-family: "Life_Lt";
  max-height: inherit;
}
.block_img_txt .right_txt h4 i {
  background: url("/images/newimg/Chevron_left.png") no-repeat center center;
  width: 11px;
  padding: 20px;
  padding-top: 16px;
  position: relative;
  z-index: 1;
}
.block_img_txt .right_txt span {
  font: 24px "Life_Rg";
  display: block;
  letter-spacing: -0.3px;
  max-height: 30px;
  padding: 0 18px 13px 18px;
  color: #333;
  overflow: hidden;
}
.block_img_txt .right_txt h4,
.block_img_txt .right_txt span {
  padding: 0;
}
.block_img_txt .right_txt .short_description {
  display: none;
}
.block_img_txt_2 {
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 222px;
  margin-top: 20px;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  float: left;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 476px;
}
.block_img_txt_2 .right {
  width: 100%;
}
.block_img_txt_2.nothing_find {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.block_img_txt_2 .left_img {
  width: 330px;
  height: 222px;
}
.block_img_txt_2 .left_img img {
  height: 100%;
}
.block_img_txt_2 .left_img.video {
  min-width: 330px;
  width: initial !important;
}
.block_img_txt_2 .left_img.video img {
  width: 100%;
}
.block_img_txt_2 .left_img.book {
  min-width: 180px;
  width: initial;
}
.block_img_txt_2 .left_img.audiobook {
  min-width: 230px;
  width: initial;
}
.block_img_txt_2 .right_txt {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 9px 15px;
}
.block_img_txt_2 .right_txt h4 {
  font-size: 30px;
  display: inline-block;
  max-height: 85px;
  padding: 13px 30px 0 18px;
  color: #333;
  overflow: hidden;
  position: relative;
  font-family: "Life_Lt";
  max-height: inherit;
}
.block_img_txt_2 .right_txt h4 i {
  background: url("/images/newimg/Chevron_left.png") no-repeat center center;
  width: 11px;
  padding: 20px;
  padding-top: 16px;
  position: relative;
  z-index: 1;
}
.block_img_txt_2 .right_txt span {
  font: 24px "Life_Rg";
  display: block;
  letter-spacing: -0.3px;
  max-height: 30px;
  padding: 0 18px 13px 18px;
  color: #333;
  overflow: hidden;
}
.block_img_txt_2 .right_txt h4,
.block_img_txt_2 .right_txt span {
  padding: 0;
}
.block_img_txt_2 .right_txt .short_description {
  display: none;
}
.block_img_txt_2 .top_img {
  width: 100%;
  max-width: 600px;
  height: 348px;
}
.block_img_txt_2 .top_img img {
  width: 100%;
  height: 348px;
}
.block_img_txt_2 .bottom_txt {
  height: inherit;
}
.block_img_txt_2 .bottom_txt h4 {
  font-size: 30px;
  display: inline-block;
  max-height: 85px;
  padding: 13px 30px 0 18px;
  color: #333;
  overflow: hidden;
  position: relative;
  font-family: "Life_Lt";
}
.block_img_txt_2 .bottom_txt h4 i {
  background: url("/images/newimg/Chevron_left.png") no-repeat center center;
  width: 11px;
  padding: 20px;
  padding-top: 16px;
  position: relative;
  z-index: 1;
}
.block_img_txt_2 .bottom_txt span {
  font: 24px "Life_Rg";
  display: block;
  letter-spacing: -0.3px;
  max-height: 30px;
  padding: 0 18px 13px 18px;
  color: #333;
  overflow: hidden;
}
.block_img_txt_2:first-child {
  margin-top: 0;
}
.cartoons_content_2 {
  padding-bottom: 60px;
}
.cartoons_content.cartoons_content_1 .ac-container.ac-collapse {
  margin-top: 0;
}
.cartoons_content.cartoons_content_1 .ac-container.ac-collapse:before {
  background: url("/images/newimg/Chevron_down_black.png") no-repeat center center;
}
.cartoons_content.cartoons_content_1 .ac-container.ac-collapse.transform_b span {
  font-family: "Life_Rg";
}
/*------------------------------------books_PAGE-------------------------------------------------*/
.books {
  font-family: "Life_Rg";
  font-size: 32px;
}
.books_content {
  padding: 0 20px;
  background-color: #f4f4f4;
  padding: 20px 20px 50px;
}
.books_content .cartoons_img {
  width: 600px;
  height: auto;
  position: relative;
  margin: 0 auto;
}
.books_content .cartoons_img img {
  width: 100%;
  height: 348px;
}
.books_content .cartoons_img.unauthorized:after {
  content: "";
  background: url("/images/newimg/Play-arrow-overlay.png") no-repeat center center;
  width: 98px;
  height: 98px;
  position: absolute;
  z-index: 999;
  top: 68px;
  margin-left: -49px;
  background: url("/images/newimg/Play-arrow-overlay_c.png") no-repeat center center;
  width: 96px;
  height: 96px;
  left: 50%;
  margin-left: -48px;
  top: 50%;
  margin-top: -48px;
  z-index: 99;
}
.books_content .cartoons_img .unauthorized:after {
  content: "";
  background: url("/images/newimg/Play-arrow-overlay.png") no-repeat center center;
  width: 98px;
  height: 98px;
  position: absolute;
  z-index: 999;
  top: 68px;
  margin-left: -49px;
  background: url("/images/newimg/Play-arrow-overlay_c.png") no-repeat center center;
  width: 96px;
  height: 96px;
  left: 50%;
  margin-left: -48px;
  top: 50%;
  margin-top: -48px;
  z-index: 99;
}
.books_content h2 {
  font: 48px/48px "Life_Lt";
  margin: 31px 0 23px;
}
.books_content p {
  font-size: 30px;
  margin-bottom: 24px;
}
.books_content .info_content span {
  display: block;
  line-height: 36px;
  font-size: 30px;
  font-family: "Life_Lt";
}
.books_content .ac-container.ac-collapse {
  margin-top: 46px;
}
.books_content .ac-container.ac-collapse p {
  font-size: 32px;
  line-height: 46px;
  padding: 16px 0 14px;
  margin: 0 22px 22px;
}
.books_content .block_loading {
  text-align: center;
  float: left;
  margin: 68px 0px 57px;
}
.books_content .block_loading span {
  text-decoration: underline;
}
.books_content .block_loading .show_more {
  width: 100%;
  height: 90px;
  color: #FBEF3C;
  border: transparent;
  font-size: 36px;
  display: inline-block;
  text-align: center;
  line-height: 80px;
  background-color: #002395;
  width: 556px;
  margin-top: 18px;
}
.books_content button {
  width: 100%;
  height: 90px;
  color: #fff;
  border: transparent;
  font-size: 36px;
  margin: 44px 0 -5px;
  background-color: #002395;
}
.books_content .read_more {
  width: 100%;
  height: 90px;
  color: #FBEF3C;
  border: transparent;
  font-size: 36px;
  display: inline-block;
  text-align: center;
  line-height: 80px;
  margin: 44px 0 -5px;
  background-color: #002395;
}
.books_content .block_img_txt {
  height: 228px;
}
.books_content .block_img_txt .left_img {
  height: 228px;
}
.books_content .block_img_txt:first-child {
  margin-top: 0;
}
.books_content .block_img_txt .left_img,
.books_content .block_img_txt_3 .left_img {
  width: 250px;
}
.books_content .block_img_txt .right_txt,
.books_content .block_img_txt_3 .right_txt {
  padding: 19px 65px 15px 24px;
}
.books_content .block_img_txt .right_txt h4,
.books_content .block_img_txt_3 .right_txt h4 {
  max-height: inherit;
}
.books_content .block_img_txt .right_txt .name_author,
.books_content .block_img_txt_3 .right_txt .name_author {
  display: none;
}
.books_content .block_img_txt.block_img_txt_3 .right_txt {
  width: calc(100% - 250px);
  width: -webkit-calc(100% - 250px);
}
.books_content .block_img_txt_3 {
  margin-top: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
}
.books_content .block_loading {
  margin: 40px 0 15px;
}
.books_content .block_loading .show_more {
  margin-top: 20px;
}
/*------------------------------------audiobooks_PAGE-------------------------------------------------*/
.audiobooks {
  font-family: "Life_Rg";
  font-size: 32px;
}
.audiobooks_content {
  padding: 0 20px;
  background-color: #f4f4f4;
  padding: 20px 20px 50px;
  padding-bottom: 40px;
}
.audiobooks_content .cartoons_img {
  width: 600px;
  height: auto;
  position: relative;
  margin: 0 auto;
}
.audiobooks_content .cartoons_img img {
  width: 100%;
  height: 348px;
}
.audiobooks_content .cartoons_img.unauthorized:after {
  content: "";
  background: url("/images/newimg/Play-arrow-overlay.png") no-repeat center center;
  width: 98px;
  height: 98px;
  position: absolute;
  z-index: 999;
  top: 68px;
  margin-left: -49px;
  background: url("/images/newimg/Play-arrow-overlay_c.png") no-repeat center center;
  width: 96px;
  height: 96px;
  left: 50%;
  margin-left: -48px;
  top: 50%;
  margin-top: -48px;
  z-index: 99;
}
.audiobooks_content .cartoons_img .unauthorized:after {
  content: "";
  background: url("/images/newimg/Play-arrow-overlay.png") no-repeat center center;
  width: 98px;
  height: 98px;
  position: absolute;
  z-index: 999;
  top: 68px;
  margin-left: -49px;
  background: url("/images/newimg/Play-arrow-overlay_c.png") no-repeat center center;
  width: 96px;
  height: 96px;
  left: 50%;
  margin-left: -48px;
  top: 50%;
  margin-top: -48px;
  z-index: 99;
}
.audiobooks_content h2 {
  font: 48px/48px "Life_Lt";
  margin: 31px 0 23px;
}
.audiobooks_content p {
  font-size: 30px;
  margin-bottom: 24px;
}
.audiobooks_content .info_content span {
  display: block;
  line-height: 36px;
  font-size: 30px;
  font-family: "Life_Lt";
}
.audiobooks_content .ac-container.ac-collapse {
  margin-top: 46px;
}
.audiobooks_content .ac-container.ac-collapse p {
  font-size: 32px;
  line-height: 46px;
  padding: 16px 0 14px;
  margin: 0 22px 22px;
}
.audiobooks_content .block_loading {
  text-align: center;
  float: left;
  margin: 68px 0px 57px;
}
.audiobooks_content .block_loading span {
  text-decoration: underline;
}
.audiobooks_content .block_loading .show_more {
  width: 100%;
  height: 90px;
  color: #FBEF3C;
  border: transparent;
  font-size: 36px;
  display: inline-block;
  text-align: center;
  line-height: 80px;
  background-color: #002395;
  width: 556px;
  margin-top: 18px;
}
.audiobooks_content button {
  width: 100%;
  height: 90px;
  color: #fff;
  border: transparent;
  font-size: 36px;
  margin: 44px 0 -5px;
  background-color: #002395;
}
.audiobooks_content .read_more {
  width: 100%;
  height: 90px;
  color: #FBEF3C;
  border: transparent;
  font-size: 36px;
  display: inline-block;
  text-align: center;
  line-height: 80px;
  margin: 44px 0 -5px;
  background-color: #002395;
}
.audiobooks_content .block_img_txt {
  height: 228px;
}
.audiobooks_content .block_img_txt .left_img {
  height: 228px;
}
.audiobooks_content .block_img_txt:first-child {
  margin-top: 0;
}
.audiobooks_content .block_img_txt .left_img,
.audiobooks_content .block_img_txt_3 .left_img {
  width: 250px;
}
.audiobooks_content .block_img_txt .right_txt,
.audiobooks_content .block_img_txt_3 .right_txt {
  padding: 19px 65px 15px 24px;
}
.audiobooks_content .block_img_txt .right_txt h4,
.audiobooks_content .block_img_txt_3 .right_txt h4 {
  max-height: inherit;
}
.audiobooks_content .block_img_txt .right_txt .name_author,
.audiobooks_content .block_img_txt_3 .right_txt .name_author {
  display: none;
}
.audiobooks_content .block_img_txt.block_img_txt_3 .right_txt {
  width: calc(100% - 250px);
  width: -webkit-calc(100% - 250px);
}
.audiobooks_content .block_img_txt_3 {
  margin-top: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
}
.audiobooks_content .block_loading {
  margin: 40px 0 15px;
}
.audiobooks_content .block_loading .show_more {
  margin-top: 20px;
}
.audiobooks_content h4 {
  font-size: 36px;
}
.audiobooks_content span.time_audio {
  padding-top: 2px;
  color: #999;
}
.audiobooks_content .block_img_txt_3 .right_txt {
  padding-top: 15px;
}
.audiobooks_content .block_img_txt_3 .right_txt h4 {
  font-size: 36px;
}
/*------------------------------------sign_in-------------------------------------------------*/
.sign_in_up {
  display: block;
  height: 100% !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 9999;
  background-color: #DADADA;
  color: #1A1A1A;
  font-size: 32px;
  font-family: "Life_Lt";
  padding: 100px 22px;
}
.sign_in_up .close-cross:before,
.sign_in_up .close-cross:after {
  background-color: #303030;
}
.sign_in_up .verifregister p {
  margin: 0;
  line-height: inherit;
}
.sign_in_up .verifregister h2 {
  margin: 30px 0;
  font-size: 40px;
}
.sign_in_up .info {
  color: #1A1A1A;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  font-size: 32px;
}
.sign_in_up a.clbtn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.sign_in_up h2 {
  text-align: center;
  font-family: "Life_Lt";
  font-size: 35px;
  margin: 30px 0 30px;
}
.sign_in_up h2 div {
  font-size: 33px;
}
.sign_in_up label {
  color: #1A1A1A;
  letter-spacing: 0.3px;
}
.sign_in_up .txt_sms {
  line-height: 38px;
}
.sign_in_up .form-control {
  position: -webkit-sticky;
  position: sticky;
  height: 90px;
  width: 100%;
  border: 2px solid #999999;
  background-color: #FFFFFF;
  -webkit-box-shadow: inset 0 6px 12px 0 rgba(0, 0, 0, 0.12);
  box-shadow: inset 0 6px 12px 0 rgba(0, 0, 0, 0.12);
  font-size: 36px;
  border-radius: 0;
  color: #333;
  margin-top: 10px;
  padding: 0 30px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: 0;
}
.sign_in_up .form-control:-input-placeholder {
  color: #999;
}
.sign_in_up .form-group:first-child {
  margin-bottom: 20px;
}
.sign_in_up .form-group:nth-child(2) .form-control {
  margin-top: 30px;
}
.sign_in_up .close_form {
  position: absolute;
  width: 44px;
  height: 44px;
  border-color: transparent;
  right: 24px;
  top: 43px;
}
.sign_in_up .check_sprite {
  display: none;
}
.sign_in_up .check_sprite:checked + label::before {
  background-position: 0 -101px;
}
.sign_in_up input[type="checkbox"] + label {
  cursor: pointer;
  background-position: 0 10px;
  line-height: 36px;
  font-weight: 400;
  margin-top: 28px;
}
.sign_in_up input[type="checkbox"] + label:before {
  content: "";
  display: inline-block;
  float: left;
  height: 45px;
  width: 62px;
  background: url("/images/newimg/check.png") no-repeat;
  background-position: 0 0px;
}
.sign_in_up a,
.sign_in_up input[type="checkbox"] + label {
  color: #fff;
  font-family: "Life_Rg";
  text-decoration: underline;
}
.sign_in_up .form_btn {
  width: 100%;
  height: 90px;
  color: #FBEF3C;
  border: transparent;
  font-size: 36px;
  display: inline-block;
  text-align: center;
  line-height: 80px;
  margin: 26px 0 27px;
  background-color: #002395;
  border-radius: 0;
}
.sign_in_up .form_btn_back {
  width: 100%;
  height: 90px;
  color: #FBEF3C;
  border: transparent;
  font-size: 36px;
  display: inline-block;
  line-height: 80px;
  background-color: #666666;
  color: #333;
  display: block;
  text-align: center;
  line-height: 90px;
  text-decoration: none;
}
.sign_in_up .form_btn_back:hover,
.sign_in_up .form_btn_back:active {
  text-decoration: none;
}
.sign_in_up .form_btn + .form_btn_back {
  margin-top: -5px;
}
.sign_in_up .form_btn:disabled {
  width: 100%;
  height: 90px;
  color: #FBEF3C;
  border: transparent;
  font-size: 36px;
  display: inline-block;
  line-height: 80px;
  background-color: #666666;
  color: #333;
  display: block;
  text-align: center;
  line-height: 90px;
  text-decoration: none;
  line-height: inherit;
}
.sign_in_up .form_btn:disabled:hover,
.sign_in_up .form_btn:disabled:active {
  text-decoration: none;
}
.sign_in_up p {
  text-align: center;
  color: #ccc;
  letter-spacing: 0.5px;
  font-size: 38px;
  line-height: 46px;
  margin: 95px 0 68px;
}
.sign_in_up .link_popup {
  float: right;
  padding-right: 15px;
  cursor: pointer;
}
.sign_in_up .link_popup.left {
  float: left;
}
.sign_in_up.sign_in_up2 input[type="checkbox"] + label {
  text-decoration: none;
  margin-top: 0;
}
.sign_in_up.sign_in_up2 .form-group:first-child {
  margin-bottom: 15px;
}
.sign_in_up.next_block h2 {
  letter-spacing: 2px;
  margin: 138px 0 60px;
}
.sign_in_up.next_block p {
  font-size: 32px;
  line-height: 38px;
  letter-spacing: 1.1px;
  margin: 0;
}
.sign_in_up.next_block .form_btn {
  margin-top: 42px;
}
.sign_in_up.next_block .form_btn + .form_btn_back {
  margin-top: 14px;
}
.sign_in_up.next_block input[type="checkbox"] + label {
  text-decoration: none;
  margin-top: 18px;
}
.modal {
  background: rgba(51, 51, 51, 0.7);
}
.modal .modal-dialog {
  margin: 20px;
}
.modal .modal-dialog .modal-content {
  border-radius: 0;
  margin-top: 320px;
}
.modal .modal-dialog .modal-content a.clbtn {
  color: #002395;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 36px;
  text-decoration: underline;
}
.modal .modal-dialog .modal-content .info {
  color: black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  font-size: 26px;
}
.modal .modal-dialog .modal-content .info h2 {
  font-family: 'Life_Rg';
  font-weight: bold;
  margin: 0 0 16px;
}
.modal .modal-dialog .modal-content a.clb .verifregister * {
  font-size: 26px;
}
.modal .modal-dialog .modal-content a.clb .verifregister p {
  margin: 0;
  line-height: inherit;
}
.modal .modal-dialog .modal-content a.clb .verifregister h2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: 'Life_Rg';
  font-weight: bold;
  margin: 15px 0;
  font-size: 32px;
}
.modal .modal-dialog .modal-content .modal-body {
  padding: 20px;
}
.modal .modal-dialog .modal-content .modal-body label {
  letter-spacing: 0.3px;
  font-size: 32px;
}
.modal .modal-dialog .modal-content .modal-body .link_popup {
  color: #333;
  font-size: 30px;
}
.modal .modal-dialog .modal-content .modal-body .form-control {
  position: -webkit-sticky;
  position: sticky;
  height: 90px;
  width: 100%;
  border: 2px solid #999999;
  background-color: #FFFFFF;
  -webkit-box-shadow: inset 0 6px 12px 0 rgba(0, 0, 0, 0.12);
  box-shadow: inset 0 6px 12px 0 rgba(0, 0, 0, 0.12);
  font-size: 36px;
  border-radius: 0;
  color: #333;
  margin-top: 10px;
  padding: 0 30px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: 0;
}
.modal .modal-dialog .modal-content .modal-body .form-control:-input-placeholder {
  color: #999;
}
.modal .modal-dialog .modal-content .modal-body.section-popup {
  padding: 30px 50px;
}
.modal .modal-dialog .modal-content p {
  font-size: 32px;
  text-align: center;
  color: #333;
  line-height: 37px;
  letter-spacing: 0.5px;
  margin: 90px 80px 57px;
}
.modal .modal-dialog .modal-content p a {
  text-decoration: underline;
  display: inline;
  margin: 0;
  color: #333;
}
.modal .modal-dialog .modal-content .form_btn {
  width: 100%;
  height: 90px;
  color: #FBEF3C;
  border: transparent;
  font-size: 36px;
  display: inline-block;
  text-align: center;
  line-height: 80px;
  margin: 26px 0 40px;
  background-color: #002395;
}
.modal .modal-dialog .modal-content .btn_back {
  width: 100%;
  height: 90px;
  color: #FBEF3C;
  border: transparent;
  font-size: 36px;
  display: inline-block;
  line-height: 80px;
  background-color: #666;
  margin-bottom: 50px;
  display: block;
  text-align: center;
  line-height: 90px;
}
.modal .modal-dialog .modal-content .btn_back:hover,
.modal .modal-dialog .modal-content .btn_back:active {
  text-decoration: none;
}
.modal .modal-dialog .modal-content .check_sprite {
  display: none;
}
.modal .modal-dialog .modal-content .check_sprite:checked + label::before {
  background-position: 0 -101px;
}
.modal .modal-dialog .modal-content input[type="checkbox"] + label {
  cursor: pointer;
  background-position: 0 10px;
  line-height: 35px;
  font-weight: 400;
  color: #666;
  font-size: 30px;
  margin: 28px 0 10px 5px;
}
.modal .modal-dialog .modal-content input[type="checkbox"] + label:before {
  content: "";
  display: inline-block;
  float: left;
  height: 45px;
  width: 63px;
  background: url("/images/newimg/check_2.png") no-repeat;
  background-position: 0 0px;
}
.modalx {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  opacity: 1;
}
.modalx .modal-content {
  margin: 96px;
}
.modalx .modal-content .buttons {
  display: grid;
  grid-gap: 20px;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  justify-items: center;
}
.modalx .modal-content h2 {
  font-family: "Life_Lt";
  font-size: 48px;
  text-align: center;
  line-height: 46px;
  margin: 28px 0 34px;
}
.modalx .modal-content .title-popup p {
  font-family: "Life_Rg";
  font-size: 29px;
  text-align: left;
  line-height: 31px;
  margin: 0;
  letter-spacing: -1px;
}
.modalx .modal-content .close {
  width: 32px;
  height: 32px;
  background: url('/images/newimg/close.png') no-repeat center center;
  position: absolute;
  right: -41px;
  top: -47px;
  opacity: 1;
}
/*-------------------------------------------landing_page--------------------------------------------------*/
.landing_page {
  position: absolute;
  background-color: white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
}
.landing_page .header_2 {
  padding: 15px 25px;
  background-color: white;
}
.landing_page .header_2.mobile {
  background-color: white;
  padding-top: 30px;
}
.landing_page .header_2 .logo_2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}
.landing_page .header_2 .logo_2 .logo_2_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.landing_page .header_2 .logo_2 .logo_2_container .mobile-logo-text {
  font-family: "Life_Lt";
  font-size: 40px;
}
@media (min-width: 768px) {
  .landing_page .header_2 .logo_2 .logo_2_container .mobile-logo-text {
    font-size: 56px;
  }
}
.landing_page .content {
  font-family: "Life_Rg";
}
.landing_page .content img {
  width: 100%;
}
.landing_page .content_page {
  padding: 20px;
  text-align: center;
}
.landing_page .content_page h2 {
  font-size: 40px;
  margin: 2px 0 10px;
}
.landing_page .content_page .mobile-h2 {
  position: relative;
  margin-top: 30px;
  font-size: 50px;
}
.landing_page .content_page p {
  font-size: 32px;
  line-height: 36px;
  letter-spacing: 0.2px;
}
.landing_page .content_page .form_btn {
  width: 100%;
  height: 90px;
  color: #FBEF3C;
  border: transparent;
  font-size: 36px;
  display: inline-block;
  text-align: center;
  line-height: 80px;
  margin: 20px 0 20px;
  background-color: #002395;
  text-transform: uppercase;
}
.landing_page .content_page .form_btn.mobile {
  font-family: 'Life_B';
}
.landing_page .content_page .btn_back {
  width: 100%;
  height: 90px;
  color: #FBEF3C;
  border: transparent;
  font-size: 36px;
  display: inline-block;
  text-align: center;
  line-height: 80px;
  background-color: #666;
  margin-bottom: 50px;
}
.landing_page .content_page .btn_back.mobile {
  background-color: white;
  color: #000000;
  margin-bottom: 0;
}
.landing_page .content_page a {
  display: block;
  font-size: 30px;
  text-decoration: underline;
  color: #333;
  letter-spacing: 0.4px;
}
.landing_page .content_page a + a {
  margin-top: 18px;
}
.landing_page .content .content_page.content_page_2 p {
  margin: 17px 0 29px;
}
.landing_page .content .content_page.content_page_2 .form-control {
  position: -webkit-sticky;
  position: sticky;
  height: 90px;
  width: 100%;
  border: 2px solid #999999;
  background-color: #FFFFFF;
  -webkit-box-shadow: inset 0 6px 12px 0 rgba(0, 0, 0, 0.12);
  box-shadow: inset 0 6px 12px 0 rgba(0, 0, 0, 0.12);
  border-radius: 0;
  color: #333;
  margin-top: 10px;
  padding: 0 30px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: 0;
  font-size: 36px;
  padding: 6px 32px;
}
.landing_page .content .content_page.content_page_2 .form-control:-input-placeholder {
  color: #999;
}
.landing_page .content .content_page.content_page_2 .form_btn {
  margin: 23px 0 20px;
}
.landing_page .content .content_page.content_page_2 .btn_back {
  margin-bottom: 30px;
}
.landing_page .content .content_page.content_page_2 a + a {
  margin-top: 5px;
}
.landing_page .modal.modal_1 .modal-dialog_1 .modal-content {
  margin-top: 0;
}
.landing_page .modal.modal_1 .modal-dialog_1 .modal-content .verifregister * {
  font-size: 26px;
}
.landing_page .modal.modal_1 .modal-dialog_1 .modal-content .verifregister p {
  margin: 0;
  line-height: inherit;
}
.landing_page .modal.modal_1 .modal-dialog_1 .modal-content .verifregister h2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: 'Life_Rg';
  font-weight: bold;
  margin: 15px 0;
  font-size: 32px;
}
.landing_page .modal.modal_1 .modal-dialog_1 .modal-content p {
  margin: 37px 0 39px;
  letter-spacing: 0.5px;
  line-height: 38px;
}
.landing_page .modal.modal_1 .modal-dialog_1 .modal-content .form_btn {
  margin: 10px 0 22px;
}
.landing_page .modal.modal_1 .modal-dialog_1 .modal-content .form_btn.phone {
  background-color: #002395;
}
.landing_page .modal.modal_1 .modal-dialog_1 .modal-content .form_btn:disabled {
  background-color: #666;
}
.landing_page .modal.modal_1 .modal-dialog_1 .modal-content .btn_back {
  margin-bottom: 20px;
}
.landing_page .modal.modal_1 .modal-dialog_1 .modal-content input[type="checkbox"] + label,
.landing_page .modal.modal_1 .modal-dialog_1 .modal-content input[type="checkbox"] + label > a {
  color: #666;
  letter-spacing: 0.5px;
}
.landing_page .modal.modal_1 .modal-dialog_1 .modal-content input[type="checkbox"] + label a,
.landing_page .modal.modal_1 .modal-dialog_1 .modal-content input[type="checkbox"] + label > a a {
  text-decoration: underline;
}
.landing_page .modal.modal_2 .modal-content {
  margin-top: 378px;
}
.landing_page .modal.modal_2 .modal-content p {
  font-family: "Life_Rg";
  margin: 70px 45px 54px;
  line-height: 45px;
}
.landing_page .modal.modal_2 .modal-content .form_btn {
  margin-bottom: 32px;
}
.landing_page .modal.modal_3 {
  background: #3e3e3e;
}
.landing_page .modal.modal_3 .modal-dialog {
  margin: 96px;
}
.landing_page .modal.modal_3 .modal-dialog .modal-content {
  margin-top: 340px;
}
.landing_page .modal.modal_3 .modal-dialog .modal-content .close {
  width: 32px;
  height: 32px;
  background: url("/images/newimg/close.png") no-repeat center center;
  position: absolute;
  right: -41px;
  top: -47px;
  opacity: 1;
}
.landing_page .modal.modal_3 .modal-dialog .modal-content h2 {
  font-family: "Life_Lt";
  font-size: 48px;
  text-align: center;
  line-height: 46px;
  margin: 28px 0 34px;
}
.landing_page .modal.modal_3 .modal-dialog .modal-content p {
  font-family: "Life_Rg";
  font-size: 29px;
  text-align: left;
  line-height: 31px;
  margin: 0;
  letter-spacing: -1px;
}
.landing_page .modal.modal_3 .modal-dialog .modal-content .form_btn {
  margin: 32px 0px 10px;
}
/*---------------------------------------games----------------------------------------------*/
.games {
  font-family: "Life_Rg";
  font-size: 32px;
}
.games_content {
  padding: 0 20px;
  background-color: #f4f4f4;
  padding: 20px 20px 50px;
  padding: 20px 20px 35px;
}
.games_content .cartoons_img {
  width: 600px;
  height: auto;
  position: relative;
  margin: 0 auto;
}
.games_content .cartoons_img img {
  width: 100%;
  height: 348px;
}
.games_content .cartoons_img.unauthorized:after {
  content: "";
  background: url("/images/newimg/Play-arrow-overlay.png") no-repeat center center;
  width: 98px;
  height: 98px;
  position: absolute;
  z-index: 999;
  top: 68px;
  margin-left: -49px;
  background: url("/images/newimg/Play-arrow-overlay_c.png") no-repeat center center;
  width: 96px;
  height: 96px;
  left: 50%;
  margin-left: -48px;
  top: 50%;
  margin-top: -48px;
  z-index: 99;
}
.games_content .cartoons_img .unauthorized:after {
  content: "";
  background: url("/images/newimg/Play-arrow-overlay.png") no-repeat center center;
  width: 98px;
  height: 98px;
  position: absolute;
  z-index: 999;
  top: 68px;
  margin-left: -49px;
  background: url("/images/newimg/Play-arrow-overlay_c.png") no-repeat center center;
  width: 96px;
  height: 96px;
  left: 50%;
  margin-left: -48px;
  top: 50%;
  margin-top: -48px;
  z-index: 99;
}
.games_content h2 {
  font: 48px/48px "Life_Lt";
  margin: 31px 0 23px;
}
.games_content p {
  font-size: 30px;
  margin-bottom: 24px;
}
.games_content .info_content span {
  display: block;
  line-height: 36px;
  font-size: 30px;
  font-family: "Life_Lt";
}
.games_content .ac-container.ac-collapse {
  margin-top: 46px;
}
.games_content .ac-container.ac-collapse p {
  font-size: 32px;
  line-height: 46px;
  padding: 16px 0 14px;
  margin: 0 22px 22px;
}
.games_content .block_loading {
  text-align: center;
  float: left;
  margin: 68px 0px 57px;
}
.games_content .block_loading span {
  text-decoration: underline;
}
.games_content .block_loading .show_more {
  width: 100%;
  height: 90px;
  color: #FBEF3C;
  border: transparent;
  font-size: 36px;
  display: inline-block;
  text-align: center;
  line-height: 80px;
  background-color: #002395;
  width: 556px;
  margin-top: 18px;
}
.games_content button {
  width: 100%;
  height: 90px;
  color: #fff;
  border: transparent;
  font-size: 36px;
  margin: 44px 0 -5px;
  background-color: #002395;
}
.games_content .read_more {
  width: 100%;
  height: 90px;
  color: #FBEF3C;
  border: transparent;
  font-size: 36px;
  display: inline-block;
  text-align: center;
  line-height: 80px;
  margin: 44px 0 -5px;
  background-color: #002395;
}
.games_content .block_img_txt {
  height: 228px;
}
.games_content .block_img_txt .left_img {
  height: 228px;
}
.games_content .block_img_txt:first-child {
  margin-top: 0;
}
.games_content .block_img_txt .left_img,
.games_content .block_img_txt_3 .left_img {
  width: 250px;
}
.games_content .block_img_txt .right_txt,
.games_content .block_img_txt_3 .right_txt {
  padding: 19px 65px 15px 24px;
}
.games_content .block_img_txt .right_txt h4,
.games_content .block_img_txt_3 .right_txt h4 {
  max-height: inherit;
}
.games_content .block_img_txt .right_txt .name_author,
.games_content .block_img_txt_3 .right_txt .name_author {
  display: none;
}
.games_content .block_img_txt.block_img_txt_3 .right_txt {
  width: calc(100% - 250px);
  width: -webkit-calc(100% - 250px);
}
.games_content .block_img_txt_3 {
  margin-top: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
}
.games_content .block_loading {
  margin: 40px 0 15px;
}
.games_content .block_loading .show_more {
  margin-top: 20px;
}
.games_content .block_img_txt .left_img {
  height: 250px;
}
.games_content .block_img_txt .placeholder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
}
.games_content .block_img_txt.block_img_txt_4 .right_txt h4 {
  line-height: 36px;
  margin-bottom: -21px;
}
.games_content .block_img_txt.block_img_txt_4 .right_txt span {
  line-height: 36px;
  max-height: 100%;
  position: relative;
  /*                span.age_label{
                                    display: inline-flex;
                                    color: #fff;
                                    justify-content: center;
                                    min-width: 34px;
                                    align-items: center;
                                    min-height: 34px;
                                    background-color: #002395;
                                    border-radius: 50%;
                                    position: relative;
                                    left: 17px;
                                }*/
}
.games_content .block_img_txt.block_img_txt_4 .right_txt .block_age {
  height: 45px;
}
.games_content .block_img_txt.block_img_txt_4 .right_txt .block_age span {
  display: inline;
}
.games_content .block_img_txt.block_img_txt_4 .right_txt .block_age_circle {
  display: inline-block;
  position: relative;
  top: 19px;
  left: 16px;
}
.games_content .block_img_txt.block_img_txt_4 .right_txt .block_age_circle_1 {
  display: inline-block;
  background-color: #002395;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  padding: 50% 0;
  width: 100%;
  position: absolute;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.games_content .block_img_txt.block_img_txt_4 .right_txt .block_age_circle_2 {
  color: #fff;
  position: relative;
  top: -0.75em;
  padding: 0 0.18em;
  font-size: 24px;
}
.games_content .block_img_txt.block_img_txt_4 .right_txt .rating_block {
  height: 35px;
}
.games_content .block_img_txt.block_img_txt_4 .right_txt .rating_block span,
.games_content .block_img_txt.block_img_txt_4 .right_txt .rating_block .rating {
  display: inline-block;
}
.games_content .block_img_txt.block_img_txt_4 .right_txt .rating_block .rating {
  position: relative;
  width: 151px;
  height: 23px;
  top: -7px;
  left: 16px;
  background: url("/images/newimg/ratings.png");
}
.games_content .block_img_txt.block_img_txt_4 .right_txt .rating_block .rating .current {
  z-index: 1;
  background: url("/images/newimg/ratings.png") left bottom;
  float: left;
  height: 23px;
}
.games_content .block_img_txt {
  height: 250px;
}
.games_content .block_img_txt .left_img {
  width: 250px;
}
.games_content .block_img_txt:first-child {
  margin-top: 0;
}
.games_content .block_img_txt .right_txt {
  padding: 17px 25px;
}
.games_content .block_loading {
  margin: 52px 0 10px;
}
.games_content .show_more {
  width: 100%;
  height: 90px;
  color: #FBEF3C;
  border: transparent;
  font-size: 36px;
  display: inline-block;
  text-align: center;
  line-height: 80px;
  margin: 38px 0 -5px;
  background-color: #002395;
}
/*--------------------------------------search---------------------------------------------*/
.block_search {
  font-family: "Life_Rg";
  font-size: 32px;
}
.block_search .search {
  background-color: #3D3D3D;
  height: 170px;
  width: 100%;
  padding: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.form-search {
  height: 90px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}
.form-search .search_input {
  width: 100%;
  height: 90px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
  border: none;
  padding: 0 0 0 20px;
  font-size: 35px;
  line-height: 90px;
  color: #333;
  -webkit-appearance: none;
  border-radius: 0;
}
.form-search .search_input::-webkit-input-placeholder {
  font-family: Life_Lt;
  font-size: 30px;
  vertical-align: middle;
  text-align: left;
  line-height: 90px;
}
.form-search .search_input:-ms-input-placeholder {
  font-family: Life_Lt;
  font-size: 30px;
  vertical-align: middle;
  text-align: left;
  line-height: 90px;
}
.form-search .search_input::placeholder {
  font-family: Life_Lt;
  font-size: 30px;
  vertical-align: middle;
  text-align: left;
  line-height: 90px;
}
.form-search .in-group-btn {
  display: inline-block;
  position: absolute;
  right: 0;
}
.form-search .in-group-btn .clear_search {
  float: left;
  width: 90px;
  margin-left: -2px;
  height: 90px;
  position: relative;
  cursor: pointer;
}
.form-search .in-group-btn .clear_search i {
  background: url("/images/newimg/close_search.png") no-repeat center center;
  width: 110px;
  height: 90px;
  position: absolute;
  left: 0;
  top: 0;
}
.form-search .in-group-btn .btn_search {
  float: left;
  margin-left: -2px;
  height: 90px;
  position: relative;
  cursor: pointer;
  width: 90px;
  background-color: #002395;
  border: none;
}
.form-search .in-group-btn .btn_search i {
  background: url("/images/newimg/close_search.png") no-repeat center center;
  width: 110px;
  height: 90px;
  position: absolute;
  left: 0;
  top: 0;
}
.form-search .in-group-btn .btn_search i {
  background: url("/images/newimg/search.png") no-repeat center center;
  width: 90px;
}
.search_result .block_bg_search {
  background-color: #fff;
}
.search_result h3 {
  font-size: 48px;
  text-align: center;
  margin: 16px 0 24px;
  letter-spacing: -1px;
}
.search_result .block_loading {
  margin: 55px 0 15px;
}
.search_modal {
  display: block;
  height: 100% !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 9999;
  background-color: #DADADA;
  color: #1A1A1A;
  font-size: 32px;
  font-family: "Life_Lt";
  padding: 100px 22px;
  padding: 0 40px;
}
.search_modal .close-cross:before,
.search_modal .close-cross:after {
  background-color: #303030;
}
.search_modal .verifregister p {
  margin: 0;
  line-height: inherit;
}
.search_modal .verifregister h2 {
  margin: 30px 0;
  font-size: 40px;
}
.search_modal .info {
  color: #1A1A1A;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  font-size: 32px;
}
.search_modal a.clbtn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.search_modal h2 {
  text-align: center;
  font-family: "Life_Lt";
  font-size: 35px;
  margin: 30px 0 30px;
}
.search_modal h2 div {
  font-size: 33px;
}
.search_modal label {
  color: #1A1A1A;
  letter-spacing: 0.3px;
}
.search_modal .txt_sms {
  line-height: 38px;
}
.search_modal .form-control {
  position: -webkit-sticky;
  position: sticky;
  height: 90px;
  width: 100%;
  border: 2px solid #999999;
  background-color: #FFFFFF;
  -webkit-box-shadow: inset 0 6px 12px 0 rgba(0, 0, 0, 0.12);
  box-shadow: inset 0 6px 12px 0 rgba(0, 0, 0, 0.12);
  font-size: 36px;
  border-radius: 0;
  color: #333;
  margin-top: 10px;
  padding: 0 30px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: 0;
}
.search_modal .form-control:-input-placeholder {
  color: #999;
}
.search_modal .form-group:first-child {
  margin-bottom: 20px;
}
.search_modal .form-group:nth-child(2) .form-control {
  margin-top: 30px;
}
.search_modal .close_form {
  position: absolute;
  width: 44px;
  height: 44px;
  border-color: transparent;
  right: 24px;
  top: 43px;
}
.search_modal .check_sprite {
  display: none;
}
.search_modal .check_sprite:checked + label::before {
  background-position: 0 -101px;
}
.search_modal input[type="checkbox"] + label {
  cursor: pointer;
  background-position: 0 10px;
  line-height: 36px;
  font-weight: 400;
  margin-top: 28px;
}
.search_modal input[type="checkbox"] + label:before {
  content: "";
  display: inline-block;
  float: left;
  height: 45px;
  width: 62px;
  background: url("/images/newimg/check.png") no-repeat;
  background-position: 0 0px;
}
.search_modal a,
.search_modal input[type="checkbox"] + label {
  color: #fff;
  font-family: "Life_Rg";
  text-decoration: underline;
}
.search_modal .form_btn {
  width: 100%;
  height: 90px;
  color: #FBEF3C;
  border: transparent;
  font-size: 36px;
  display: inline-block;
  text-align: center;
  line-height: 80px;
  margin: 26px 0 27px;
  background-color: #002395;
  border-radius: 0;
}
.search_modal .form_btn_back {
  width: 100%;
  height: 90px;
  color: #FBEF3C;
  border: transparent;
  font-size: 36px;
  display: inline-block;
  line-height: 80px;
  background-color: #666666;
  color: #333;
  display: block;
  text-align: center;
  line-height: 90px;
  text-decoration: none;
}
.search_modal .form_btn_back:hover,
.search_modal .form_btn_back:active {
  text-decoration: none;
}
.search_modal .form_btn + .form_btn_back {
  margin-top: -5px;
}
.search_modal .form_btn:disabled {
  width: 100%;
  height: 90px;
  color: #FBEF3C;
  border: transparent;
  font-size: 36px;
  display: inline-block;
  line-height: 80px;
  background-color: #666666;
  color: #333;
  display: block;
  text-align: center;
  line-height: 90px;
  text-decoration: none;
  line-height: inherit;
}
.search_modal .form_btn:disabled:hover,
.search_modal .form_btn:disabled:active {
  text-decoration: none;
}
.search_modal p {
  text-align: center;
  color: #ccc;
  letter-spacing: 0.5px;
  font-size: 38px;
  line-height: 46px;
  margin: 95px 0 68px;
}
.search_modal .link_popup {
  float: right;
  padding-right: 15px;
  cursor: pointer;
}
.search_modal .link_popup.left {
  float: left;
}
.search_modal .form-search .clear_search i {
  background: url("/images/newimg/close_search_2.png") no-repeat center center;
}
.search_modal .block_queries {
  padding: 0 40px;
  text-align: center;
}
.search_modal .block_queries h3 {
  text-align: center;
  font-family: Life_Lt;
  font-size: 40px;
  margin: 50px 0 58px;
}
.search_modal .block_queries .btn_queries_block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.search_modal .block_queries .btn_queries_block .btn_queries {
  padding: 16px 28px;
  background-color: #e5e5e5;
  border-radius: 10px;
  border-color: transparent;
  font-size: 36px;
  display: inline-block;
  color: #333;
  margin-bottom: 20px;
}
.search_modal .close_form {
  right: 40px;
  top: 42px;
}
.search_modal h2 {
  margin: 147px 0 49px;
}
/*----------------------------------------books_reader------------------------------------------------*/
/*--------------------MIXIN-----------------------------*/
/*------------------------------------------------------*/
.books_reader {
  font-family: "Life_Rg";
  background-color: #fff;
}
.books_reader_header {
  float: left;
  width: 100%;
  -webkit-box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
  z-index: 2;
  position: relative;
}
.books_reader_header .btn_menu_box {
  height: 113px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.books_reader_header .btn_menu_box .close_books_reader {
  width: 68px;
  height: 68px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.books_reader_header .btn_menu_box .close_books_reader button {
  width: 68px;
  height: 68px;
  background-color: transparent;
  border: transparent;
  position: relative;
}
.books_reader_header .btn_menu_box .close_books_reader button i {
  width: 68px;
  height: 68px;
  position: absolute;
  left: 0;
  top: 0;
  background: url("/images/newimg/chevron_reader.png") no-repeat center center;
}
.books_reader_header .btn_menu_box .nav_books_reader {
  height: 68px;
  width: 413px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 10px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.books_reader_header .btn_menu_box .nav_books_reader_list {
  width: 68px;
  height: 68px;
  background-color: transparent;
  border: transparent;
  position: relative;
}
.books_reader_header .btn_menu_box .nav_books_reader_list i {
  width: 68px;
  height: 68px;
  position: absolute;
  left: 0;
  top: 0;
  background: url("/images/newimg/list_reader.png") no-repeat center top;
  height: 36px;
  top: 16px;
}
.books_reader_header .btn_menu_box .nav_books_reader_bookmark {
  width: 68px;
  height: 68px;
  background-color: transparent;
  border: transparent;
  position: relative;
}
.books_reader_header .btn_menu_box .nav_books_reader_bookmark i {
  width: 68px;
  height: 68px;
  position: absolute;
  left: 0;
  top: 0;
  background: url("/images/newimg/bookmark_reader.png") no-repeat center top;
  height: 42px;
  top: 11px;
}
.books_reader_header .btn_menu_box .nav_books_reader_font {
  width: 68px;
  height: 68px;
  background-color: transparent;
  border: transparent;
  position: relative;
}
.books_reader_header .btn_menu_box .nav_books_reader_font i {
  width: 68px;
  height: 68px;
  position: absolute;
  left: 0;
  top: 0;
  background: url("/images/newimg/font_reader.png") no-repeat center top;
  height: 42px;
  top: 14px;
}
.books_reader_header .btn_menu_box .nav_books_reader_list.active i {
  background-position: center bottom;
  height: 36px;
  top: 15px;
}
.books_reader_header .btn_menu_box .nav_books_reader_bookmark.active i {
  background-position: center bottom;
  height: 42px;
  top: 11px;
}
.books_reader_header .btn_menu_box .nav_books_reader_font.active i {
  background-position: center bottom;
  height: 42px;
  top: 8px;
}
.books_reader_header .btn_menu_box .nav_books_reader_search {
  width: 68px;
  height: 68px;
  background-color: transparent;
  border: transparent;
  position: relative;
}
.books_reader_header .btn_menu_box .nav_books_reader_search i {
  width: 68px;
  height: 68px;
  position: absolute;
  left: 0;
  top: 0;
  background: url("/images/newimg/search_reader.png") no-repeat center center;
}
.books_reader .content {
  float: left;
  width: 100%;
  height: 100%;
  padding: 30px 20px 129px;
  background-color: #fff;
}
.books_reader .content .content_txt {
  line-height: 48px;
}
.books_reader .content .box_range {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 120px;
  width: 100%;
  text-align: center;
  background-color: #fff;
}
.books_reader .content .box_range span {
  font-size: 32px;
  color: #999;
}
.books_reader .content .box_range input[type="range"] {
  width: 600px;
  margin: 33px auto 20px;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  height: 4px;
  border: none;
  border-radius: 0;
  background: #999999;
  outline: none;
  position: relative;
}
.books_reader .content .box_range input[type="range"]::-moz-range-track {
  border: inherit;
  background: transparent;
}
.books_reader .content .box_range input[type="range"]::-ms-track {
  border: inherit;
  color: transparent;
  background: transparent;
}
.books_reader .content .box_range input[type="range"]::-ms-fill-lower,
.books_reader .content .box_range input[type="range"]::-ms-fill-upper {
  background: transparent;
}
.books_reader .content .box_range input[type="range"]::-ms-tooltip {
  display: none;
}
.books_reader .content .box_range input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 50%;
  background-color: #002395;
  cursor: pointer;
  margin: -10px 0;
}
.books_reader .content .box_range input[type="range"]::-moz-range-thumb {
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 50%;
  background-color: #002395;
  cursor: pointer;
  margin: -10px 0;
}
.books_reader .content .box_range input[type="range"]::-ms-thumb {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 0;
  background-color: #002395;
  cursor: pointer;
  margin: -10px 0;
}
.books_reader.reverse_color {
  background-color: #000;
}
.books_reader.reverse_color .books_reader_header {
  background-color: #333;
}
.books_reader.reverse_color .btn_menu_box .close_books_reader button i {
  background: url("/images/newimg/chevron_reader_w.png") no-repeat center center;
}
.books_reader.reverse_color .btn_menu_box .nav_books_reader_list i {
  background-position: center center;
  height: 68px;
  top: 0;
}
.books_reader.reverse_color .btn_menu_box .nav_books_reader_bookmark i {
  background-position: center center;
  height: 68px;
  top: 0;
}
.books_reader.reverse_color .btn_menu_box .nav_books_reader_font i {
  background-position: center center;
  height: 68px;
  top: 0;
}
.books_reader.reverse_color .btn_menu_box .nav_books_reader_search i {
  background: url("/images/newimg/search.png") no-repeat center center;
}
.books_reader.reverse_color .content {
  background-color: #000;
}
.books_reader.reverse_color .content .content_txt {
  color: #fff;
}
.books_reader.reverse_color .content .box_range {
  background-color: #000;
}
.modal_font_color {
  position: absolute;
  top: 111px;
  left: 0;
  width: 100%;
  z-index: 999;
  text-align: center;
}
.modal_font_color h3,
.modal_font_color span {
  font-size: 36px;
}
.modal_font_color h3 {
  padding: 34px 0 26px;
}
.modal_font_color span {
  display: inline-block;
  margin: 0 102px;
}
.modal_font_color .modal_font_color_1 {
  background-color: #EBEBEB;
  border-bottom: 1px solid #D4D4D4;
  height: 227px;
}
.modal_font_color .modal_font_color_1 .btn_font_color_1 {
  width: 153px;
  height: 83px;
  font-size: 32px;
  border-color: transparent;
  border-radius: 10px;
  background-color: #fff;
}
.modal_font_color .modal_font_color_1 .btn_font_color_1.btn_font_color_2 {
  background-color: #002395;
  font-size: 46px;
  color: #fff;
}
.modal_font_color .modal_font_color_2 {
  background-color: #fff;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border-bottom: 1px solid #f4f4f4;
}
.modal_font_color .modal_font_color_2 h3 {
  padding: 44px 0 42px;
}
.menu_modal {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.menu_modal li {
  display: inline-block;
  width: 50%;
  border-top: 1px solid #d1d1d1;
}
.menu_modal li a {
  display: block;
  color: #666;
  font-size: 36px;
  font-family: "Life_Lt";
  text-decoration: none;
  padding: 24px 0;
}
.menu_modal li.active {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}
.menu_modal li.active a {
  color: #333;
  font-family: "Life_Rg";
}
.menu_modal li.active:before {
  content: "";
  background-color: #e70200;
  height: 4px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.menu_modal li.active:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #e91300 transparent transparent transparent;
  position: absolute;
  top: 3px;
  left: 50%;
  margin-left: -5px;
}
.modal_setting_content {
  position: absolute;
  top: 111px;
  left: 0;
  width: 100%;
  z-index: 999;
  overflow-y: scroll;
  bottom: 0;
  background-color: #fff;
}
.modal_setting_content .modal_setting_content_1 {
  background-color: #EBEBEB;
}
.modal_setting_content .modal_setting_content_1 ul {
  text-align: center;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.modal_setting_content .modal_setting_content_1 ul li {
  display: inline-block;
  width: 50%;
  border-top: 1px solid #d1d1d1;
}
.modal_setting_content .modal_setting_content_1 ul li a {
  display: block;
  color: #666;
  font-size: 36px;
  font-family: "Life_Lt";
  text-decoration: none;
  padding: 24px 0;
}
.modal_setting_content .modal_setting_content_1 ul li.active {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}
.modal_setting_content .modal_setting_content_1 ul li.active a {
  color: #333;
  font-family: "Life_Rg";
}
.modal_setting_content .modal_setting_content_1 ul li.active:before {
  content: "";
  background-color: #e70200;
  height: 4px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.modal_setting_content .modal_setting_content_1 ul li.active:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #e91300 transparent transparent transparent;
  position: absolute;
  top: 3px;
  left: 50%;
  margin-left: -5px;
}
.modal_setting_content .modal_setting_content_1 ul li {
  border-top: 0;
}
.modal_setting_content .modal_setting_content_1 ul li a {
  padding: 17px 0 23px;
}
.modal_setting_content .modal_setting_content_1 ul li.active:before {
  content: "";
  top: inherit;
  bottom: -2px;
}
.modal_setting_content .modal_setting_content_1 ul li.active:after {
  content: "";
  top: inherit;
  bottom: -11px;
}
.modal_setting_content .modal_setting_content_2 ul {
  padding-top: 6px;
  width: 100%;
}
.modal_setting_content .modal_setting_content_2 ul li {
  margin: 0 42px;
}
.modal_setting_content .modal_setting_content_2 ul li a {
  color: #333;
  font-size: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 32px 0 34px;
}
.modal_setting_content .modal_setting_content_2 ul li a:hover,
.modal_setting_content .modal_setting_content_2 ul li a:active,
.modal_setting_content .modal_setting_content_2 ul li a:focus {
  text-decoration: none;
}
.modal_setting_content .modal_setting_content_2 ul li a span:first-child {
  width: 90%;
}
.modal_setting_content .modal_setting_content_2 ul li a span.txt_calendar {
  font-size: 23px;
  display: block;
  position: relative;
  margin: -6px 0 -24px;
  padding-left: 32px;
  letter-spacing: 0.5px;
}
.modal_setting_content .modal_setting_content_2 ul li a span.txt_calendar:after {
  content: "";
  background: url("/images/newimg/btn_bookmark_small.png") no-repeat center center;
  position: absolute;
  left: 2px;
  top: 9px;
  width: 17px;
  height: 20px;
}
.modal_setting_content .modal_setting_content_2 ul li + li {
  border-top: 2px solid #d4d4d4;
}
/*---------------------------------------404--------------------------------------------*/
.block_404 {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 2;
  background: url("/images/newimg/404.jpg") no-repeat center center;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.block_404 .box_404 {
  text-align: center;
  color: #fff;
}
.block_404 .box_404 h2 {
  font-size: 320px;
  line-height: 0.87;
}
.block_404 .box_404 span {
  font-family: "Life_Rg";
  font-size: 56px;
}
/*----------------------------------------player-------------------------------------------*/
.audiobook_player {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: scroll;
  z-index: 999;
}
.audiobook_player .audiobook_player_page {
  min-height: 100vh;
  background-color: #EAEAEA;
}
.audiobook_player .audiobook_player_page .block_player_img {
  height: 450px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.audiobook_player .audiobook_player_page .block_player_img .bg_img_blur {
  position: absolute;
  height: 450px;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}
.audiobook_player .audiobook_player_page .block_player_img .block_player_img_small {
  position: relative;
  z-index: 2;
}
.audiobook_player .audiobook_player_page .player {
  margin: 0 20px;
}
.audiobook_player .audiobook_player_page .player_time {
  height: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 30px;
}
.audiobook_player .audiobook_player_page .player_time .progress-holder {
  position: relative;
  width: 100%;
  background-color: #666;
  height: 9px;
  margin: 50px;
}
.audiobook_player .audiobook_player_page .player_time .progress-bar {
  background-color: #666;
  width: 100%;
  height: 9px;
}
.audiobook_player .audiobook_player_page .player_time .line {
  position: absolute;
  top: 0;
  left: 0;
  height: 9px;
  background-color: #e60b00;
  text-align: right;
}
.audiobook_player .audiobook_player_page .player_time .line:before {
  content: '';
  width: 40px;
  height: 40px;
  border: none;
  position: absolute;
  border-radius: 50%;
  background-color: #002395;
  cursor: pointer;
  margin: -16px -3px;
}
.audiobook_player .audiobook_player_page .player_time input[type="range"] {
  width: 400px;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  height: 6px;
  border: none;
  border-radius: 0;
  background: #666;
  outline: none;
  position: relative;
}
.audiobook_player .audiobook_player_page .player_time input[type="range"]::-moz-range-track {
  border: inherit;
  background: transparent;
}
.audiobook_player .audiobook_player_page .player_time input[type="range"]::-ms-track {
  border: inherit;
  color: transparent;
  background: transparent;
}
.audiobook_player .audiobook_player_page .player_time input[type="range"]::-ms-fill-lower,
.audiobook_player .audiobook_player_page .player_time input[type="range"]::-ms-fill-upper {
  background: transparent;
}
.audiobook_player .audiobook_player_page .player_time input[type="range"]::-ms-tooltip {
  display: none;
}
.audiobook_player .audiobook_player_page .player_time input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  background-color: #002395;
  cursor: pointer;
  margin: -17px 0;
}
.audiobook_player .audiobook_player_page .player_time input[type="range"]::-moz-range-thumb {
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  background-color: #002395;
  cursor: pointer;
  margin: -17px 0;
}
.audiobook_player .audiobook_player_page .player_time input[type="range"]::-ms-thumb {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 0;
  background-color: #002395;
  cursor: pointer;
  margin: -17px 0;
}
.audiobook_player .audiobook_player_page .player_time .left-time,
.audiobook_player .audiobook_player_page .player_time .right-time {
  font-size: 32px;
  color: #666;
}
.audiobook_player .audiobook_player_page .player_btn {
  margin: 9px auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.audiobook_player .audiobook_player_page .player_btn button {
  width: 99px;
  height: 99px;
  border-radius: 50%;
}
.audiobook_player .audiobook_player_page .player_btn_left,
.audiobook_player .audiobook_player_page .player_btn_right,
.audiobook_player .audiobook_player_page .player_btn_play {
  border-color: transparent;
  background-color: transparent;
  position: relative;
  margin: 0 -21px;
}
.audiobook_player .audiobook_player_page .player_btn_left i,
.audiobook_player .audiobook_player_page .player_btn_right i,
.audiobook_player .audiobook_player_page .player_btn_play i {
  width: 99px;
  height: 99px;
  position: absolute;
  top: -2px;
  left: -2px;
}
.audiobook_player .audiobook_player_page .player_btn_left i {
  background: url("/images/newimg/play_left.svg") no-repeat center center;
  background-size: 50px;
}
.audiobook_player .audiobook_player_page .player_btn_left i:hover {
  background: url("/images/newimg/play_left-hover.svg") no-repeat center center;
  background-size: 50px;
}
.audiobook_player .audiobook_player_page .player_btn_right i {
  background: url("/images/newimg/play_right.svg") no-repeat center center;
  background-size: 50px;
}
.audiobook_player .audiobook_player_page .player_btn_right i:hover {
  background: url("/images/newimg/play_right-hover.svg") no-repeat center center;
  background-size: 50px;
}
.audiobook_player .audiobook_player_page .player_btn_play i {
  background: url("/images/newimg/play.svg") no-repeat center center;
}
.audiobook_player .audiobook_player_page .player_btn_play i:hover {
  background: url("/images/newimg/play-hover.svg") no-repeat center center;
}
.audiobook_player .audiobook_player_page .player_btn .player_btn_play.player_btn_pause i {
  background: url("/images/newimg/play.svg") no-repeat center center;
}
.audiobook_player .audiobook_player_page .player_btn .player_btn_play.player_btn_pause i:hover {
  background: url("/images/newimg/play-hover.svg") no-repeat center center;
}
.audiobook_player .audiobook_player_page .player_btn .player_btn_play.player_btn_pause.active i {
  background: url("/images/newimg/play_pause.svg") no-repeat center center;
}
.audiobook_player .audiobook_player_page .player_btn .player_btn_play.player_btn_pause.active i:hover {
  background: url("/images/newimg/play_pause-hover.svg") no-repeat center center;
}
.audiobook_player .audiobook_player_page .player_btn .player_btn_play.player_btn_pause.active:disabled {
  background: url("/images/newimg/play_pause-disabled.svg") no-repeat center center;
}
.audiobook_player .audiobook_player_page .player .txt_player_name,
.audiobook_player .audiobook_player_page .player .txt_player_author {
  display: none;
}
.audiobook_player .audiobook_player_page .player_list {
  margin: 35px auto 30px;
  width: 600px;
  background-color: #fff;
  -webkit-box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.2);
}
.audiobook_player .audiobook_player_page .player_list ul {
  width: 100%;
}
.audiobook_player .audiobook_player_page .player_list ul li {
  height: 125px;
}
.audiobook_player .audiobook_player_page .player_list ul li a {
  color: #333;
  font-size: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-family: "Life_Rg";
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 125px;
  padding: 0 30px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.audiobook_player .audiobook_player_page .player_list ul li a span:first-child {
  width: 380px;
  line-height: 40px;
}
.audiobook_player .audiobook_player_page .player_list ul li a .icon_list_player {
  background: url("/images/newimg/play_list_icon.svg") no-repeat center center;
  width: 35px;
  height: 35px;
  position: relative;
  top: 0;
}
.audiobook_player .audiobook_player_page .player_list ul li a:active,
.audiobook_player .audiobook_player_page .player_list ul li a:hover {
  background-color: #F6F6F6;
  font-family: "Life_B";
}
.audiobook_player .audiobook_player_page .player_list ul li a:active .icon_list_player,
.audiobook_player .audiobook_player_page .player_list ul li a:hover .icon_list_player {
  background: url("/images/newimg/play_list_icon-hover.svg") no-repeat center center;
  width: 35px;
  height: 35px;
}
.audiobook_player .audiobook_player_page .player_list ul li.active a {
  background-color: #F6F6F6;
  font-family: "Life_B";
}
.audiobook_player .audiobook_player_page .player_list ul li.active a .icon_list_player {
  background: url("/images/newimg/play_list_icon-hover.svg") no-repeat center center;
  width: 35px;
  height: 35px;
}
.audiobook_player .audiobook_player_page .player_list ul li + li {
  border-top: 1px solid #ccc;
}
/*----------------------------------------------player_button---------------------------------------*/
.block-video {
  visibility: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.block-video .quality-selector .btn-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  margin-top: 15px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.block-video .quality-selector .btn-group .btn.btn-video-quality {
  font-size: 24px;
  margin-top: 5px;
}
.block-video .quality-selector .btn-group .quality-selector-caption {
  padding-right: 10px;
}
/*---------------------------------------------------------------------------------------------------*/
.js-endless-container {
  overflow: auto;
}
.js-endless-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  float: left;
  visibility: hidden;
}
/*----------------------------------------------responsive--------------------------------------------*/
/*----------------------------------------------MIXIN-------------------------------------------------*/
@media screen and (min-width: 768px) {
  .container {
    width: 100%;
  }
  .reverse_color_bg .col-xs-12 {
    background-color: #f4f4f4;
  }
  .reverse_color_bg .col-xs-12.bg_block {
    background-color: #fff;
  }
  .reverse_color_bg .home_menu + .col-xs-12 .txt_slider {
    margin-top: 8px;
  }
  .reverse_color_bg .bg_block + .col-xs-12 {
    padding-bottom: 7px;
  }
  .header {
    position: static;
    height: 60px;
  }
  .header_btn {
    margin: 4px 19px 0 0;
  }
  .header_btn_search {
    background-color: transparent;
    width: 50px;
    height: 50px;
  }
  .header_btn_search i {
    background: url("/images/newimg/search_tablet.svg") no-repeat center center;
    background-size: 40px;
  }
  .header_btn_user {
    background-color: transparent;
    width: 50px;
    height: 50px;
  }
  .header_btn_user i {
    background: url("/images/newimg/admin_tablet.svg") no-repeat center center;
    background-size: 40px;
  }
  .header_btn_nav {
    background-color: transparent;
    width: 50px;
    height: 50px;
  }
  .header_btn_nav i {
    width: 36px;
    background: none;
    position: absolute;
    left: 10px;
    top: 4px;
    height: 36px;
    display: block;
    height: 32px;
    width: 32px;
    border-bottom: 2px solid #002395;
  }
  .header_btn_nav i::before,
  .header_btn_nav i::after {
    position: absolute;
    content: ' ';
    width: 32px;
    left: 0;
    height: 2px;
    background-color: #002395;
  }
  .header_btn_nav i::before {
    top: 19px;
  }
  .header_btn_nav i::after {
    top: 8px;
  }
  .header_menu {
    width: 374px;
    -webkit-transform: translate(374px, 0px);
    transform: translate(374px, 0px);
    z-index: 9999;
    overflow: auto;
    height: inherit !important;
  }
  .header_menu a,
  .header_menu label {
    font-size: 20px;
  }
  .header_menu .header_popup {
    height: 78px;
  }
  .header_menu .header_popup .btn_lang {
    font-size: 16px;
    padding-left: 6px;
    margin-top: 10px;
    padding-right: 24px;
  }
  .header_menu .header_popup .btn_lang i {
    background-size: 65%;
    top: 6px;
  }
  .header_menu .header_popup .header_btn button:last-child i {
    background-size: 53%;
  }
  .header_menu .header_popup .header_btn_search {
    width: 44px;
    height: 44px;
  }
  .header_menu .header_popup .header_btn_search i {
    background: url("/images/newimg/search.png") no-repeat center center;
    background-size: 79%;
  }
  .header_menu .header_popup .header_btn_user {
    width: 44px;
    height: 44px;
    margin-left: 14px;
  }
  .header_menu .header_popup .header_btn_user i {
    background: url("/images/newimg/admin.png") no-repeat center center;
    background-size: 67%;
  }
  .header_menu .header_popup .header_btn button:last-child i {
    background: none;
  }
  .header_menu .header_popup .header_btn_nav {
    width: 44px;
    height: 44px;
    margin-left: 14px;
  }
  .header_menu .header_popup .header_btn_nav i {
    background: none;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 36px;
    height: 36px;
    outline: none;
    cursor: pointer;
    border-bottom: none;
    z-index: 1;
  }
  .header_menu .header_popup .header_btn_nav i::before,
  .header_menu .header_popup .header_btn_nav i::after {
    position: absolute;
    left: 10px;
    top: 5px;
    content: ' ';
    height: 30px;
    width: 2px;
    background-color: #002395;
  }
  .header_menu .header_popup .header_btn_nav i::before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .header_menu .header_popup .header_btn_nav i::after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .header_menu ul {
    margin-top: 13px;
    margin-bottom: 49px;
  }
  .header_menu ul li {
    padding-left: 20px;
    padding-right: 20px;
  }
  .header_menu ul li a {
    line-height: 44px;
  }
  .header_menu ul li a .sm {
    -webkit-transform: rotate(90deg) scale(1);
    transform: rotate(90deg) scale(1);
  }
  .header_menu ul li a::after {
    background-size: 71%;
    right: 31px;
    top: 11px;
  }
  .header_menu form {
    margin: 26px 0;
  }
  .header_menu form .col-xs-12 + .col-xs-12 {
    margin-top: 22px;
  }
  .header_menu #toggle {
    background-color: #424242;
  }
  .header_menu #toggle .radio_sprite:checked + label::before {
    background-position: -1px -36px;
  }
  .header_menu #toggle label {
    background-position: 0 10px;
    line-height: 22px;
    margin-left: 0px;
    font-size: 18px;
  }
  .header_menu #toggle label:before {
    height: 20px;
    width: 25px;
    background-position: -1px 1px;
    background-size: 73%;
  }
  .footer {
    height: 100%;
    padding: 45px 7px 45px 3px;
  }
  .footer_soc,
  .footer span {
    display: none;
  }
  .footer .footer_btn {
    width: 70%;
    float: left;
  }
  .footer .footer_btn a {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: 16px;
    border-bottom: none;
    text-indent: 0;
    padding: 13px 0;
    letter-spacing: 0.3px;
    border-top: none;
  }
  .footer .footer_btn a:after {
    display: none;
  }
  .footer .footer_btn a .arrow_bordered {
    display: none;
  }
  .footer .footer_btn a + a {
    margin-left: 6px;
    border-top: none;
  }
  .footer .footer_btn a + a::before {
    content: '|';
    margin-right: 9px;
  }
  .footer .copyright {
    width: 30%;
    float: left;
  }
  .footer .copyright span {
    width: 100%;
    display: inline-block;
    text-align: right;
    margin: 0;
    font-size: 16px;
    margin-top: 5px;
  }
  .home_banner_container {
    height: auto;
  }
  .home_banner_slide {
    height: auto;
    background-size: contain;
  }
  .home_banner_slide:first-child {
    background: url("/images/newimg/top_banners/MAIN_banner_mobile_cartoons_tabletland_desktop.jpg") no-repeat center bottom;
  }
  .home_banner_slide_btn {
    background-color: #002395;
    border-color: #002395;
    color: #fff;
    width: 352px;
    height: 90px;
    font-size: 36px;
    position: absolute;
    top: 68.5%;
    left: 50%;
    margin-left: -176px;
    font-size: 20px;
    width: 186px;
    height: 50px;
    margin-left: -93px;
    top: 67.7%;
  }
  .home_banner_slide h2 {
    top: 11%;
  }
  .home_banner_slide h2 span {
    font-family: "Life_B";
    letter-spacing: -0.6px;
  }
  .owl-dots {
    bottom: 33px;
  }
  .owl-dots .owl-dot {
    width: 10px;
    height: 10px;
    margin: 0 8px;
  }
  .owl-dots .owl-dot.active {
    height: 14px;
    width: 14px;
    border: 2px solid #fff;
    margin: 0 6px;
  }
  .home_menu {
    height: 164px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-top: 16px solid #002395;
  }
  .home_menu ul {
    width: 570px;
    margin: 0 auto;
  }
  .home_menu ul li {
    height: 148px;
    width: 142.5px;
  }
  .home_menu ul li a {
    font-size: 20px;
    line-height: 190px;
  }
  .home_menu ul li .home_menu_icon_1:before {
    background-size: 63%;
    top: 0;
  }
  .home_menu ul li .home_menu_icon_2:before {
    background-size: 49%;
    top: 0;
  }
  .home_menu ul li .home_menu_icon_3:before {
    background-size: 63%;
    top: 0;
  }
  .home_menu ul li .home_menu_icon_4:before {
    background-size: 63%;
    top: 0;
  }
  .slider_1 {
    padding: 0;
  }
  .slider_1 .slick-slide {
    max-width: 248px;
    padding: 0 20px 0 0;
  }
  .slider_1 .item {
    margin-left: 21px;
  }
  .slider_1 a img {
    width: 228px !important;
    height: 130px;
  }
  .slider_1 a h4 {
    font-size: 16px;
    margin: 12px 0 22px;
  }
  .txt_slider {
    margin: 8px 0 18px;
  }
  .txt_slider h3 {
    font-size: 32px;
    letter-spacing: -0.3px;
    padding-left: 5px;
  }
  .txt_slider a {
    font-size: 16px;
    padding: 11px 7px 0 0;
    letter-spacing: 0px;
  }
  .home_menu + .col-xs-12 .txt_slider {
    margin-top: 16px;
  }
  .slider_2 {
    padding: 0;
  }
  .slider_2 .owl-stage-outer {
    padding-top: 13px;
  }
  .slider_2 .cartoons_slide {
    width: 166px;
    height: 181px;
    margin-left: 20.8px;
    margin-bottom: 30px;
  }
  .slider_2 .cartoons_slide img {
    width: 166px;
    height: 111px;
  }
  .slider_2 .cartoons_slide h4 {
    font-size: 16px;
    max-height: 40px;
    padding: 8px 10px 0 10px;
  }
  .slider_2 .cartoons_slide h4 i {
    padding-top: 18px;
    background-size: 15%;
    margin-left: -7px;
  }
  .slider_2 .cartoons_slide span {
    font-size: 12px;
    max-height: 16px;
    padding: 0 10px 13px 10px;
    margin-top: -4px;
  }
  .slider_2 .cartoons_slide .label_text,
  .slider_2 .cartoons_slide .label_text_free {
    max-height: 16px;
    margin: 18px 34px;
    font: 12px "Life_B";
    color: white;
  }
  .slider_2 .cartoons_slide .label_text_free {
    margin: 18px 2px;
  }
  .slider_2 .books_slide {
    width: 90px;
    height: 171px;
    margin-left: 20.57142857px;
    margin-bottom: 20px;
  }
  .slider_2 .books_slide img {
    width: 90px;
    height: 125px;
  }
  .slider_2 .books_slide h4 {
    font-size: 16px;
    max-height: 40px;
    padding: 8px 10px 0 10px;
    padding: 7px 6px 0 8px;
    line-height: 15px;
    max-height: 38px;
  }
  .slider_2 .books_slide h4 i {
    padding-top: 18px;
    background-size: 15%;
    margin-left: -7px;
  }
  .slider_2 .books_slide h4 i {
    padding-top: 19px;
    background-size: 12%;
    margin-left: -11px;
  }
  .slider_2 .books_slide span {
    font-size: 12px;
    max-height: 16px;
    padding: 0 10px 13px 10px;
    margin-top: -4px;
    padding: 0 8px 13px 8px;
  }
  .slider_2 .books_slide .label_text,
  .slider_2 .books_slide .label_text_free {
    max-height: 16px;
    margin: 18px 34px;
    font: 12px "Life_B";
    color: white;
  }
  .slider_2 .books_slide .label_text_free {
    margin: 18px 2px;
  }
  .slider_2 .books_slide.game_slide,
  .slider_2 .audiobooks_slide.game_slide,
  .slider_2 .games_slide.game_slide {
    height: 171px;
  }
  .slider_2 .books_slide.game_slide img,
  .slider_2 .audiobooks_slide.game_slide img,
  .slider_2 .games_slide.game_slide img {
    height: 105px;
  }
  .slider_2 .books_slide.game_slide {
    margin-bottom: 30px;
    height: 171px;
  }
  .slider_2 .books_slide.game_slide img {
    height: 125px;
  }
  .slider_2 .audiobooks_slide {
    width: 90px;
    height: 171px;
    margin-left: 20.57142857px;
    margin-bottom: 20px;
    width: 105px;
  }
  .slider_2 .audiobooks_slide img {
    width: 90px;
    height: 125px;
  }
  .slider_2 .audiobooks_slide h4 {
    font-size: 16px;
    max-height: 40px;
    padding: 8px 10px 0 10px;
    padding: 7px 6px 0 8px;
    line-height: 15px;
    max-height: 38px;
  }
  .slider_2 .audiobooks_slide h4 i {
    padding-top: 18px;
    background-size: 15%;
    margin-left: -7px;
  }
  .slider_2 .audiobooks_slide h4 i {
    padding-top: 19px;
    background-size: 12%;
    margin-left: -11px;
  }
  .slider_2 .audiobooks_slide span {
    font-size: 12px;
    max-height: 16px;
    padding: 0 10px 13px 10px;
    margin-top: -4px;
    padding: 0 8px 13px 8px;
  }
  .slider_2 .audiobooks_slide .label_text,
  .slider_2 .audiobooks_slide .label_text_free {
    max-height: 16px;
    margin: 18px 34px;
    font: 12px "Life_B";
    color: white;
  }
  .slider_2 .audiobooks_slide .label_text_free {
    margin: 18px 2px;
  }
  .slider_2 .audiobooks_slide img {
    height: 105px;
    width: 105px;
  }
  .slider_2 .games_slide {
    width: 90px;
    height: 171px;
    margin-left: 20.57142857px;
    margin-bottom: 20px;
    width: 105px;
  }
  .slider_2 .games_slide img {
    width: 90px;
    height: 125px;
  }
  .slider_2 .games_slide h4 {
    font-size: 16px;
    max-height: 40px;
    padding: 8px 10px 0 10px;
    padding: 7px 6px 0 8px;
    line-height: 15px;
    max-height: 38px;
  }
  .slider_2 .games_slide h4 i {
    padding-top: 18px;
    background-size: 15%;
    margin-left: -7px;
  }
  .slider_2 .games_slide h4 i {
    padding-top: 19px;
    background-size: 12%;
    margin-left: -11px;
  }
  .slider_2 .games_slide span {
    font-size: 12px;
    max-height: 16px;
    padding: 0 10px 13px 10px;
    margin-top: -4px;
    padding: 0 8px 13px 8px;
  }
  .slider_2 .games_slide .label_text,
  .slider_2 .games_slide .label_text_free {
    max-height: 16px;
    margin: 18px 34px;
    font: 12px "Life_B";
    color: white;
  }
  .slider_2 .games_slide .label_text_free {
    margin: 18px 2px;
  }
  .slider_2 .games_slide img {
    width: 105px;
    height: 105px;
  }
  .label_box,
  .label_box_free {
    background-size: 42%;
    left: -37px;
    top: -21px;
  }
  .label_box.top,
  .label_box_free.top {
    top: 8px;
  }
  .label_box_free {
    left: -63px;
  }
  /*-----------------------------------------cabinet-----------------------------------------------*/
  .category_title {
    width: 100%;
    background-size: cover;
  }
  .category_title h2 {
    font-size: 56px;
    letter-spacing: 0.9px;
  }
  .breadcrumbs {
    padding: 12.5px 22px 11px;
    border-bottom: 1px solid #ccc;
    background-color: #f4f4f4;
    font-size: 18px;
  }
  .breadcrumbs a {
    letter-spacing: -0.5px;
  }
  .breadcrumbs span {
    letter-spacing: -0.3px;
  }
  .breadcrumbs i {
    background-size: 70%;
    vertical-align: -11px;
    margin: 0 14px;
  }
  .cabinet_content {
    padding: 0 22px;
    text-align: center;
  }
  .cabinet_content .tel {
    font-size: 18px;
    color: #666;
    margin: 18px 0;
    padding: 0 3px 0 2px;
  }
  .cabinet_content .tel span:last-child {
    letter-spacing: 0;
  }
  .cabinet_content .sign_out_account {
    width: 100%;
    height: 90px;
    color: #FBEF3C;
    border: transparent;
    font-size: 36px;
    display: inline-block;
    text-align: center;
    line-height: 80px;
    background-color: #999;
    font-size: 20px;
    width: 215px;
    height: 50px;
    line-height: 49px;
    margin: 25px 0 287px 0;
  }
  .ac-container {
    min-height: 81px;
    text-align: left;
  }
  .ac-container:before {
    right: 31px;
    top: 23px;
    background-size: 62%;
  }
  .ac-container a,
  .ac-container span {
    font-size: 20px;
    line-height: 77px;
    padding-left: 30px;
  }
  .ac-container + .ac-container {
    margin-top: 9px;
  }
  .container {
    width: 100%;
    max-width: 1200px;
  }
  /*-----------------------------------------cabinet_2-----------------------------------------------*/
  .subscription_management {
    margin: 50px 0;
  }
  .subscription_management span {
    color: #002395;
  }
  .subscription_management_header h3 {
    font-size: 34px;
    line-height: 40px;
    padding: 39px 0;
  }
  .subscription_management_content {
    text-align: left;
    padding: 32px 20px;
  }
  .subscription_management_content p {
    font-size: 20px;
    line-height: 28px;
  }
  .subscription_management_content p + p {
    margin-top: 28px;
  }
  .subscription_management_footer .btn_deactivate {
    width: 100%;
    height: 90px;
    color: #FBEF3C;
    border: transparent;
    font-size: 36px;
    display: inline-block;
    text-align: center;
    line-height: 80px;
    background-color: #666;
    margin: 23px 0 50px;
    width: 215px;
    height: 50px;
    font-size: 20px;
    line-height: 45px;
  }
  .subscription_management .label_manage {
    background-size: 70%;
    left: -41px;
    top: -40px;
  }
  /*--------------------------------------cabinet_page_3--------------------------------------------*/
  .cabinet_content.history {
    padding: 21px 22px;
  }
  .cabinet_content.history .sign_out_account {
    margin-bottom: 30px;
  }
  .ac-collapse::before {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .ac-collapse .collapse {
    padding-top: 3px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .ac-collapse .collapse .box_info {
    min-height: 80px;
  }
  .ac-collapse .collapse .box_info_img img {
    height: 24px;
  }
  .ac-collapse .collapse .box_info_name {
    padding: 22px 17px 16px 17px;
  }
  .ac-collapse .collapse .box_info_name h4 {
    font-size: 20px;
  }
  .ac-collapse .collapse .box_info_name span {
    font-size: 15px;
  }
  .ac-collapse .collapse .box_info_time span {
    text-align: right;
    font: 20.5px "Life_Rg";
  }
  .block_img_txt_2 {
    width: calc(30.56103333%);
    height: 100%;
    margin: 0 1.38615% 20px;
  }
  .block_img_txt_2 .top_img {
    width: 100%;
    display: block;
    max-width: 100%;
    height: auto;
    margin-right: auto;
    margin-left: auto;
  }
  .block_img_txt_2 .top_img img {
    width: 100%;
    height: 150px;
  }
  .block_img_txt_2 .bottom_txt {
    height: 82px;
  }
  .block_img_txt_2 .bottom_txt h4 {
    font-size: 16px;
  }
  .block_img_txt_2 .bottom_txt span {
    font-size: 14px;
  }
  /*---------------------------------------------cartoon------------------------------------------------*/
  .cartoons_content.cartoons_content_2 {
    padding: 13px 11px 22px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .cartoons_content.cartoons_content_2 .block_loading {
    margin: 8px 0 12px;
  }
  .cartoons_content.cartoons_content_2 .bottom_txt a {
    display: block;
  }
  .cartoons_content.cartoons_content_2 .bottom_txt a h4 {
    display: block;
    line-height: 16px;
    padding: 13px 10px 0 10px;
    max-height: 55px;
  }
  .cartoons_content.cartoons_content_2 .bottom_txt a span {
    max-height: 25px;
    padding: 7px 10px 13px 10px;
  }
  .cartoons_content {
    padding: 13px 22px 22px;
  }
  .cartoons_content .cartoons_img {
    width: 100%;
    height: auto;
  }
  .cartoons_content .cartoons_img img {
    width: 100%;
    height: auto;
  }
  .cartoons_content .cartoons_img.unauthorized::after {
    background-size: 60%;
    margin-top: -48px;
  }
  .cartoons_content h2 {
    font: 24px/24px "Life_Lt";
    margin: 31px 0 12px;
  }
  .cartoons_content p {
    font-size: 18px;
    margin-bottom: 7px;
  }
  .cartoons_content .info_content span {
    font-size: 18px;
    line-height: 24px;
  }
  .cartoons_content .ac-container.ac-collapse {
    margin: 10px 10px -6px;
    margin: inherit;
    margin-top: 37px;
    margin-bottom: -6px;
    min-height: 50px;
  }
  .cartoons_content .ac-container.ac-collapse p {
    font-size: 18px;
    line-height: 32px;
    padding: 10px 0 16px;
    margin: 0 15px;
  }
  .cartoons_content .ac-container.ac-collapse span {
    line-height: 50px;
  }
  .cartoons_content .ac-container.ac-collapse::before {
    top: 8px;
  }
  .cartoons_content .ac-container.ac-collapse .collapse {
    padding: 0 15px;
  }
  .cartoons_content .ac-container.ac-collapse .collapse p {
    margin: 0 15px;
    padding: 10px 0 16px;
    font-size: 18px !important;
    line-height: 32px;
  }
  .cartoons_content .ac-container.ac-collapse span {
    line-height: 50px;
  }
  .cartoons_content .ac-container.ac-collapse::before {
    top: 8px;
  }
  .cartoons_content .block_loading {
    margin: 16px 0 27px;
    width: 100%;
  }
  .cartoons_content .block_loading > span {
    display: block;
  }
  .cartoons_content .block_loading span {
    text-decoration: none;
    font-size: 20px;
  }
  .cartoons_content .block_loading .show_more {
    width: 215px;
    height: 50px;
    font-size: 20px;
    line-height: 49px;
  }
  .cartoons_content .txt_slider {
    width: 100%;
    margin: -4px 5px 18px;
  }
  /*------------------------------------------cartoon_3----------------------------------------------*/
  .cartoons_content.cartoons_content_1 .ac-container.ac-collapse {
    margin-bottom: -3px;
  }
  .cartoons_content.cartoons_content_1 .ac-container.ac-collapse::before {
    background-size: 58%;
  }
  .cartoons_content.cartoons_content_1 .ac-container.ac-collapse .collapse {
    padding: 0;
  }
  .cartoons_content.cartoons_content_1 .block_img_txt {
    height: 198px;
  }
  .cartoons_content.cartoons_content_1 .block_img_txt .left_img {
    width: 352px;
    height: 198px;
  }
  .cartoons_content.cartoons_content_1 .block_img_txt .left_img img {
    width: 352px;
    height: 198px;
  }
  .cartoons_content.cartoons_content_1 .block_img_txt .right_txt {
    padding: 16px 24px;
  }
  .cartoons_content.cartoons_content_1 .block_img_txt .right_txt h4 {
    font-size: 24px;
    max-height: 52px;
  }
  .cartoons_content.cartoons_content_1 .block_img_txt .right_txt span {
    font-size: 20px;
    margin: -4px 0 9px;
    max-height: 25px;
  }
  .cartoons_content.cartoons_content_1 .block_img_txt .right_txt .short_description {
    display: block;
    font-size: 18px;
    color: #333;
    line-height: 24px;
    max-height: 72px;
    overflow: hidden;
  }
  .collapse.filter_ac a {
    line-height: 50.5px;
  }
  /*------------------------------------books------------------------------------------------*/
  .books_content {
    padding: 13px 11px 22px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .books_content .block_img_txt {
    margin: 0 10px 20px;
    width: calc(30.56103333%);
    height: 95px;
  }
  .books_content .block_img_txt .left_img {
    width: 104px;
    height: 95px;
  }
  .books_content .block_img_txt .right_txt {
    padding: 8px 10px 15px;
  }
  .books_content .block_img_txt .right_txt h4 {
    display: block;
    font-size: 14px;
    line-height: 14px;
    max-height: 72px;
  }
  .books_content .block_img_txt .right_txt h4 i {
    background-size: 49%;
    padding: 6px;
  }
  .books_content .block_img_txt .right_txt span {
    font-size: 12px;
  }
  .books_content .block_img_txt.block_img_txt_3 {
    margin-top: -6px;
    width: 100%;
    height: 100%;
    margin-top: 0;
  }
  .books_content .block_img_txt.block_img_txt_3 .left_img {
    width: 163px;
    height: 228px;
  }
  .books_content .block_img_txt.block_img_txt_3 .left_img img {
    width: 100%;
    height: 100%;
  }
  .books_content .block_img_txt.block_img_txt_3 .right_txt {
    padding: 19px 65px 15px 24px;
  }
  .books_content .block_img_txt.block_img_txt_3 .right_txt h4 {
    font-size: 34px;
    line-height: 36px;
    max-height: 70px;
  }
  .books_content .block_img_txt.block_img_txt_3 .right_txt span {
    font-size: 20px;
    max-height: 25px;
  }
  .books_content .block_img_txt.block_img_txt_3 .right_txt .name_author {
    display: block;
    margin-bottom: 10px;
  }
  .books_content .block_img_txt.block_img_txt_3 .right_txt .time_audio {
    margin-top: 6px;
  }
  .books_content .read_more {
    width: 215px;
    height: 50px;
    line-height: 49px;
    font-size: 20px;
    position: absolute;
    left: 295px;
    top: 147px;
    left: 208px;
  }
  .books_content .ac-container.ac-collapse {
    min-height: 50px;
    margin: 10px 10px -6px;
  }
  .books_content .ac-container.ac-collapse p {
    font-size: 18px;
    line-height: 32px;
    padding: 10px 0 16px;
    margin: 0 15px;
  }
  .books_content .ac-container.ac-collapse span {
    line-height: 50px;
  }
  .books_content .ac-container.ac-collapse::before {
    top: 8px;
  }
  .books_content .ac-container.ac-collapse .collapse {
    padding: 0 15px;
  }
  .books_content .ac-container.ac-collapse .collapse p {
    margin: 0 15px;
    padding: 10px 0 16px;
    font-size: 18px !important;
    line-height: 32px;
  }
  .books_content .block_loading {
    margin: 16px 0 27px;
    width: 100%;
    margin: 6px 0 16px;
  }
  .books_content .block_loading > span {
    display: block;
  }
  .books_content .block_loading span {
    text-decoration: none;
    font-size: 20px;
  }
  .books_content .block_loading .show_more {
    width: 215px;
    height: 50px;
    font-size: 20px;
    line-height: 49px;
  }
  .books .cartoons_content {
    padding: 13px 11px 22px;
  }
  .books .cartoons_content .block_loading {
    margin: 8px 0 12px;
  }
  /*------------------------------------audiobooks_PAGE-------------------------------------------------*/
  .audiobooks {
    font-family: "Life_Rg";
    font-size: 32px;
  }
  .audiobooks .cartoons_content {
    padding: 13px 11px 22px;
  }
  .audiobooks_content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 14px 11px 23px 11px;
  }
  .audiobooks_content .block_img_txt {
    margin: 0 10px 20px;
    width: calc(30.56103333%);
    height: 95px;
  }
  .audiobooks_content .block_img_txt .left_img {
    width: 104px;
    height: 95px;
  }
  .audiobooks_content .block_img_txt .right_txt {
    padding: 8px 10px 15px;
  }
  .audiobooks_content .block_img_txt .right_txt h4 {
    display: block;
    font-size: 14px;
    line-height: 14px;
    max-height: 72px;
  }
  .audiobooks_content .block_img_txt .right_txt h4 i {
    background-size: 49%;
    padding: 6px;
  }
  .audiobooks_content .block_img_txt .right_txt span {
    font-size: 12px;
  }
  .audiobooks_content .block_loading {
    margin: 16px 0 27px;
    width: 100%;
    margin: 5px 0 0px;
  }
  .audiobooks_content .block_loading > span {
    display: block;
  }
  .audiobooks_content .block_loading span {
    text-decoration: none;
    font-size: 20px;
  }
  .audiobooks_content .block_loading .show_more {
    width: 215px;
    height: 50px;
    font-size: 20px;
    line-height: 49px;
  }
  .audiobooks_content .block_loading .show_more {
    margin-bottom: 11px;
  }
  .audiobooks_content .block_img_txt.block_img_txt_3 {
    width: 100%;
    height: 100%;
    margin-top: -6px;
    margin-top: 0;
    margin-bottom: 30px;
  }
  .audiobooks_content .block_img_txt.block_img_txt_3 .left_img {
    width: 163px;
    height: 228px;
  }
  .audiobooks_content .block_img_txt.block_img_txt_3 .left_img img {
    width: 100%;
    height: 100%;
  }
  .audiobooks_content .block_img_txt.block_img_txt_3 .right_txt {
    padding: 19px 65px 15px 24px;
  }
  .audiobooks_content .block_img_txt.block_img_txt_3 .right_txt h4 {
    font-size: 34px;
    line-height: 36px;
    max-height: 70px;
  }
  .audiobooks_content .block_img_txt.block_img_txt_3 .right_txt span {
    font-size: 20px;
    max-height: 25px;
  }
  .audiobooks_content .block_img_txt.block_img_txt_3 .right_txt .name_author {
    display: block;
    margin-bottom: 10px;
  }
  .audiobooks_content .block_img_txt.block_img_txt_3 .right_txt .time_audio {
    margin-top: 6px;
  }
  .audiobooks_content .block_img_txt.block_img_txt_3 .left_img {
    width: 250px;
  }
  .audiobooks_content .block_img_txt.block_img_txt_3 .left_img img {
    width: 100%;
  }
  .audiobooks_content .read_more {
    width: 215px;
    height: 50px;
    line-height: 49px;
    font-size: 20px;
    position: absolute;
    left: 295px;
    top: 147px;
  }
  .audiobooks_content .ac-container.ac-collapse {
    min-height: 50px;
    margin: 10px 10px -6px;
    margin: 0 10px;
  }
  .audiobooks_content .ac-container.ac-collapse p {
    font-size: 18px;
    line-height: 32px;
    padding: 10px 0 16px;
    margin: 0 15px;
  }
  .audiobooks_content .ac-container.ac-collapse span {
    line-height: 50px;
  }
  .audiobooks_content .ac-container.ac-collapse::before {
    top: 8px;
  }
  .audiobooks_content .ac-container.ac-collapse .collapse {
    padding: 0 15px;
  }
  .audiobooks_content .ac-container.ac-collapse .collapse p {
    margin: 0 15px;
    padding: 10px 0 16px;
    font-size: 18px !important;
    line-height: 32px;
  }
  /*----------------------------------------popup---------------------------------------------*/
  .overlay_bg {
    background: rgba(0, 0, 0, 0.9);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 9999;
  }
  .sign_in_up {
    z-index: 99999;
    padding: 20px;
    width: 393px;
    height: inherit !important;
    left: 50%;
    margin-left: -196.5px;
    top: 104px;
    background-color: #fff;
    overflow: inherit;
  }
  .sign_in_up .close-cross:before,
  .sign_in_up .close-cross:after {
    background-color: white;
  }
  .sign_in_up .login-label {
    display: inherit;
  }
  .sign_in_up .info {
    color: #333;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    font-size: 20px;
  }
  .sign_in_up .verifregister h2 {
    font-size: 24px;
  }
  .sign_in_up a.clbtn {
    color: #002395;
  }
  .sign_in_up h2,
  .sign_in_up label,
  .sign_in_up .form-control,
  .sign_in_up .form_btn:disabled,
  .sign_in_up a {
    color: #333;
  }
  .sign_in_up h2 {
    font-weight: bold;
    font-size: 20px;
    font-family: 'Life_Lt';
  }
  .sign_in_up h2 div {
    font-size: 18px;
  }
  .sign_in_up label,
  .sign_in_up a {
    font-size: 18px;
  }
  .sign_in_up .form-control,
  .sign_in_up .form_btn,
  .sign_in_up .form_btn:disabled {
    font-size: 20px;
    height: 50px;
  }
  .sign_in_up .form_btn,
  .sign_in_up .form_btn:disabled {
    line-height: 38px;
  }
  .sign_in_up .form-control {
    -webkit-box-shadow: inset 0 3px 7px 0 rgba(0, 0, 0, 0.12);
    box-shadow: inset 0 3px 7px 0 rgba(0, 0, 0, 0.12);
    border: 1px solid #afafaf;
    margin-top: 3px;
    padding: 0 19px;
  }
  .sign_in_up .form_btn {
    margin: 9px 0 -4px;
  }
  .sign_in_up .check_sprite {
    display: none;
  }
  .sign_in_up .check_sprite:checked + label::before {
    background-position: 0 -72px;
  }
  .sign_in_up .sign_in_up .form-group:first-child {
    margin-bottom: 50px;
  }
  .sign_in_up input[type="checkbox"] + label {
    line-height: 26px;
    margin-top: 21px;
    color: #333;
    font-size: 18px;
    text-decoration: none;
    letter-spacing: 0.1px;
  }
  .sign_in_up input[type="checkbox"] + label a {
    color: #333;
    font-size: 14px;
  }
  .sign_in_up input[type="checkbox"] + label:before {
    height: 45px;
    width: 46px;
    background: url("/images/newimg/check_2.png") no-repeat;
    background-position: 0 0px;
    background-size: 70%;
  }
  .sign_in_up .form-group:first-child {
    margin-bottom: 15px;
  }
  .sign_in_up .form-group:last-child .col-xs-12 {
    margin-bottom: 30px;
  }
  .sign_in_up .close_form {
    position: fixed;
    background: none;
    right: 17px;
    top: 14px;
    width: 36px;
    height: 36px;
    outline: none;
    cursor: pointer;
    z-index: 1;
  }
  .sign_in_up .close_form::before,
  .sign_in_up .close_form::after {
    position: absolute;
    left: 13px;
    top: 2px;
    content: ' ';
    height: 38px;
    width: 2px;
    background-color: #fff;
  }
  .sign_in_up .close_form::before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .sign_in_up .close_form::after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .sign_in_up p {
    font-size: 18px;
    line-height: 32px;
    color: #333;
    letter-spacing: -0.5px;
    margin: 46px 0 35px;
  }
  .sign_in_up .form_btn + .form_btn_back {
    margin-top: 25px;
    height: 50px;
    line-height: 49px;
    font-size: 20px;
    margin-bottom: 15px;
    cursor: pointer;
  }
  .sign_in_up .link_popup {
    margin-top: 17px;
    margin-bottom: 34px;
  }
  .sign_in_up.sign_in_up2 .form-group:last-child .col-xs-12 {
    margin-top: -20px;
    margin-bottom: 15px;
  }
  .sign_in_up.sign_in_up2 input[type="checkbox"] + label {
    font-size: 14px;
    margin-top: 0;
  }
  .sign_in_up.sign_in_up2 .form_btn {
    margin: 26px 0 27px;
  }
  .sign_in_up.sign_in_up2 label {
    line-height: 20px;
  }
  .sign_in_up.next_block h2 {
    letter-spacing: 0px;
    margin: 35px 0 46px;
  }
  .sign_in_up.next_block p {
    font-size: 18px;
    line-height: 32px;
    color: #333;
    letter-spacing: 0.2px;
  }
  .sign_in_up.next_block .form_btn + .form_btn_back {
    margin-top: 25px;
    height: 50px;
    line-height: 49px;
    font-size: 20px;
    margin-bottom: 0;
  }
  .sign_in_up.next_block input[type="checkbox"] + label {
    margin-top: 26px;
    font-size: 14px;
  }
  .sign_in_up.next_block .form-group:last-child .col-xs-12 {
    margin-bottom: 22px;
  }
  .modal .modal-dialog {
    margin: 20px auto;
    width: 395px;
  }
  .modal .modal-dialog .modal-content {
    margin-top: 245px;
  }
  .modal .modal-dialog .modal-content .modal-body {
    text-align: center;
  }
  .modal .modal-dialog .modal-content p {
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.26px;
    margin: 49px 10px 48px;
  }
  .modal .modal-dialog .modal-content .form_btn {
    width: 312px;
    height: auto;
    line-height: 49px;
    font-size: 20px;
    margin-bottom: 20px;
  }
  .modalx .modal-dialog {
    width: 600px;
  }
  .modalx .modal-body .form_btn {
    width: auto;
    padding: 0 30px;
  }
  /*---------------------------------------landing_page-------------------------------------------*/
  .landing_page {
    background-color: #333;
  }
  .landing_page .header_2 .row {
    height: 60px;
  }
  .landing_page .header_2 .row .logo_2 {
    background: url("/images/newimg/logo_2_tablet.png") no-repeat center center;
  }
  .landing_page .content img {
    width: 100%;
    height: auto;
  }
  .landing_page .content .content_page {
    width: 353px;
    margin: 0 auto;
    padding: 0;
  }
  .landing_page .content .content_page h2 {
    color: #fff;
    font-size: 56px;
    font-family: "Life_Lt";
    margin: 20px 0 28px;
  }
  .landing_page .content .content_page p {
    color: #ccc;
    font-size: 18px;
    line-height: 22px;
  }
  .landing_page .content .content_page .form_btn {
    margin: 37px 0 20px;
  }
  .landing_page .content .content_page .form_btn,
  .landing_page .content .content_page .btn_back {
    height: 50px;
    line-height: 49px;
    font-size: 20px;
  }
  .landing_page .content .content_page .btn_back {
    margin-bottom: 21px;
    color: #333;
  }
  .landing_page .content .content_page a {
    color: #fff;
    font-size: 14px;
  }
  .landing_page .content .content_page a + a {
    margin-top: 9px;
    margin-bottom: 30px;
  }
  .landing_page .modal.modal_1 .modal-dialog.modal-dialog_1 {
    width: 352px;
  }
  .landing_page .modal.modal_1 .modal-dialog.modal-dialog_1 .modal-content {
    margin-top: 259px;
  }
  .landing_page .modal.modal_1 .modal-dialog.modal-dialog_1 .modal-content .modal-body {
    padding: 34px 20px;
  }
  .landing_page .modal.modal_1 .modal-dialog.modal-dialog_1 .modal-content p {
    font-size: 16px;
    font-family: "Life_Rg";
    margin: 16px 0 19px;
    letter-spacing: 0.1px;
    line-height: 19px;
  }
  .landing_page .modal.modal_1 .modal-dialog.modal-dialog_1 .modal-content .form_btn {
    margin: 35px 0 22px;
  }
  .landing_page .modal.modal_1 .modal-dialog.modal-dialog_1 .modal-content .btn_back {
    margin-bottom: -4px;
    height: 50px;
    line-height: 49px;
    font-size: 20px;
  }
  .landing_page .modal.modal_1 .modal-dialog.modal-dialog_1 .modal-content .check_sprite {
    display: none;
  }
  .landing_page .modal.modal_1 .modal-dialog.modal-dialog_1 .modal-content .check_sprite:checked + label::before {
    background-position: 0 -72px;
  }
  .landing_page .modal.modal_1 .modal-dialog.modal-dialog_1 .modal-content input[type="checkbox"] + label {
    line-height: 14px;
    margin-top: 21px;
    color: #333;
    font-size: 18px;
    text-decoration: none;
    letter-spacing: 0.1px;
    font-size: 14px;
    font-family: "Life_Rg";
    text-align: left;
    margin-left: 0;
  }
  .landing_page .modal.modal_1 .modal-dialog.modal-dialog_1 .modal-content input[type="checkbox"] + label a {
    color: #333;
    font-size: 14px;
  }
  .landing_page .modal.modal_1 .modal-dialog.modal-dialog_1 .modal-content input[type="checkbox"] + label:before {
    height: 45px;
    width: 46px;
    background: url("/images/newimg/check_2.png") no-repeat;
    background-position: 0 0px;
    background-size: 70%;
  }
  .landing_page .modal.modal_1 .modal-dialog.modal-dialog_1 .modal-content .form-group {
    margin-bottom: -35px;
  }
  .landing_page .modal.modal_2 .modal-dialog {
    width: 352px;
  }
  .landing_page .modal.modal_2 .modal-dialog .modal-content {
    margin-top: 391px;
  }
  .landing_page .modal.modal_2 .modal-dialog .modal-content p {
    font-family: "Life_Rg";
    margin: 62px 10px 49px;
    line-height: 20px;
  }
  .landing_page .modal.modal_2 .modal-dialog .modal-content .form_btn {
    margin-bottom: 20px;
  }
  .landing_page .modal.modal_3 .modal-dialog {
    width: 352px;
    margin: 0 auto;
  }
  .landing_page .modal.modal_3 .modal-dialog .modal-content {
    margin-top: 304px;
  }
  .landing_page .modal.modal_3 .modal-dialog .modal-content .close {
    right: -48px;
    top: -49px;
    background-size: 90%;
  }
  .landing_page .modal.modal_3 .modal-dialog .modal-content h2 {
    font-family: "Life_Rg";
    font-size: 36px;
    line-height: 42px;
    margin: 24px 0 39px;
  }
  .landing_page .modal.modal_3 .modal-dialog .modal-content p {
    font-size: 18px;
    text-align: center;
    line-height: 22px;
    letter-spacing: 0.2px;
  }
  .landing_page .modal.modal_3 .modal-dialog .modal-content .form_btn {
    margin: 72px 0 20px;
  }
  .landing_page .content .content_page.content_page_2 p {
    margin: 17px 0 29px;
  }
  .landing_page .content .content_page.content_page_2 .form-control {
    -webkit-box-shadow: inset 0 3px 7px 0 rgba(0, 0, 0, 0.12);
    box-shadow: inset 0 3px 7px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 20px;
    border: 1px solid #afafaf;
    height: 50px;
    padding: 10px 20px;
  }
  .landing_page .content .content_page.content_page_2 .form-control:-input-placeholder {
    color: #333;
  }
  .landing_page .content .content_page.content_page_2 .btn_back {
    margin-bottom: 20px;
  }
  /*------------------------------------audiobooks_PAGE-------------------------------------------------*/
  .games {
    font-family: "Life_Rg";
    font-size: 32px;
  }
  .games .cartoons_content {
    padding: 13px 11px 22px;
  }
  .games_content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 14px 11px 23px 11px;
  }
  .games_content .block_img_txt {
    margin: 0 10px 20px;
    width: calc(30.56103333%);
    height: 95px;
  }
  .games_content .block_img_txt .left_img {
    width: 104px;
    height: 95px;
  }
  .games_content .block_img_txt .right_txt {
    padding: 8px 10px 15px;
  }
  .games_content .block_img_txt .right_txt h4 {
    display: block;
    font-size: 14px;
    line-height: 14px;
    max-height: 72px;
  }
  .games_content .block_img_txt .right_txt h4 i {
    background-size: 49%;
    padding: 6px;
  }
  .games_content .block_img_txt .right_txt span {
    font-size: 12px;
  }
  .games_content .block_loading {
    margin: 16px 0 27px;
    width: 100%;
    margin: 5px 0 0px;
  }
  .games_content .block_loading > span {
    display: block;
  }
  .games_content .block_loading span {
    text-decoration: none;
    font-size: 20px;
  }
  .games_content .block_loading .show_more {
    width: 215px;
    height: 50px;
    font-size: 20px;
    line-height: 49px;
  }
  .games_content .block_loading .show_more {
    margin-bottom: 11px;
  }
  .games_content .block_img_txt.block_img_txt_3 {
    width: 100%;
    height: 100%;
    margin-top: -6px;
    margin-top: 0;
    margin-bottom: 30px;
  }
  .games_content .block_img_txt.block_img_txt_3 .left_img {
    width: 163px;
    height: 228px;
  }
  .games_content .block_img_txt.block_img_txt_3 .left_img img {
    width: 100%;
    height: 100%;
  }
  .games_content .block_img_txt.block_img_txt_3 .right_txt {
    padding: 19px 65px 15px 24px;
  }
  .games_content .block_img_txt.block_img_txt_3 .right_txt h4 {
    font-size: 34px;
    line-height: 36px;
    max-height: 70px;
  }
  .games_content .block_img_txt.block_img_txt_3 .right_txt span {
    font-size: 20px;
    max-height: 25px;
  }
  .games_content .block_img_txt.block_img_txt_3 .right_txt .name_author {
    display: block;
    margin-bottom: 10px;
  }
  .games_content .block_img_txt.block_img_txt_3 .right_txt .time_audio {
    margin-top: 6px;
  }
  .games_content .block_img_txt.block_img_txt_3 .left_img {
    width: 250px;
  }
  .games_content .block_img_txt.block_img_txt_3 .left_img img {
    width: 100%;
  }
  .games_content .read_more {
    width: 215px;
    height: 50px;
    line-height: 49px;
    font-size: 20px;
    position: absolute;
    left: 295px;
    top: 147px;
  }
  .games_content .ac-container.ac-collapse {
    min-height: 50px;
    margin: 10px 10px -6px;
    margin: 0 10px;
  }
  .games_content .ac-container.ac-collapse p {
    font-size: 18px;
    line-height: 32px;
    padding: 10px 0 16px;
    margin: 0 15px;
  }
  .games_content .ac-container.ac-collapse span {
    line-height: 50px;
  }
  .games_content .ac-container.ac-collapse::before {
    top: 8px;
  }
  .games_content .ac-container.ac-collapse .collapse {
    padding: 0 15px;
  }
  .games_content .ac-container.ac-collapse .collapse p {
    margin: 0 15px;
    padding: 10px 0 16px;
    font-size: 18px !important;
    line-height: 32px;
  }
  .games_content .block_img_txt .left_img {
    width: 95px;
  }
  .games_content .read_more {
    top: 170px;
  }
  .games_content .block_img_txt.block_img_txt_3.block_img_txt_4 {
    height: 250px;
  }
  .games_content .block_img_txt.block_img_txt_3.block_img_txt_4 img {
    height: 250px;
  }
  .games_content .block_img_txt.block_img_txt_3.block_img_txt_4 .right_txt h4 {
    display: block;
  }
  .games_content .block_img_txt.block_img_txt_3.block_img_txt_4 .right_txt .block_age {
    margin-top: 17px;
  }
  .games_content .block_img_txt.block_img_txt_3.block_img_txt_4 .right_txt .block_age_circle {
    left: 10px;
  }
  .games_content .block_img_txt.block_img_txt_3.block_img_txt_4 .right_txt .block_age_circle .block_age_circle_2 {
    font-size: 22px;
  }
  .games_content .block_img_txt.block_img_txt_3.block_img_txt_4 .right_txt .rating_block {
    margin-top: -8px;
    margin-bottom: 1px;
  }
  .games_content .block_img_txt.block_img_txt_3.block_img_txt_4 .right_txt .rating_block .rating {
    top: -2px;
    left: 7px;
  }
  .games_content .block_img_txt.block_img_txt_3.block_img_txt_4 .right_txt span {
    letter-spacing: 0;
    max-height: 30px;
  }
  /*--------------------------------------search---------------------------------------------*/
  .block_search .search {
    height: 115px;
  }
  .block_search .form-search {
    width: 480px;
    height: 50px;
  }
  .block_search .form-search .search_input {
    width: 426px;
    height: 50px;
    padding: 0 20px;
    font-size: 25px;
    line-height: 50px;
    color: #767676;
    border-top: 1px solid #afafaf;
    border-bottom: 1px solid #afafaf;
    border-left: 1px solid #afafaf;
    -webkit-box-shadow: inset 0 3px 7px 0 rgba(0, 0, 0, 0.12);
    box-shadow: inset 0 3px 7px 0 rgba(0, 0, 0, 0.12);
  }
  .block_search .form-search .search_input::-webkit-input-placeholder {
    font-size: 25px;
    vertical-align: middle;
    text-align: left;
    line-height: 50px;
  }
  .block_search .form-search .search_input:-ms-input-placeholder {
    font-size: 25px;
    vertical-align: middle;
    text-align: left;
    line-height: 50px;
  }
  .block_search .form-search .search_input::placeholder {
    font-size: 25px;
    vertical-align: middle;
    text-align: left;
    line-height: 50px;
  }
  .block_search .form-search .in-group-btn {
    width: 104px;
    overflow: hidden;
    margin-left: -50px;
  }
  .block_search .form-search .in-group-btn .close-cross:before,
  .block_search .form-search .in-group-btn .close-cross:after {
    height: 35px;
  }
  .block_search .form-search .in-group-btn .clear_search {
    width: 54px;
    height: 50px;
    cursor: pointer;
    background: transparent;
  }
  .block_search .form-search .in-group-btn .clear_search i {
    background: url("/images/newimg/close_search.png") no-repeat center center;
    width: 54px;
    height: 50px;
    background-size: 50%;
  }
  .block_search .form-search .in-group-btn .btn_search {
    width: 50px;
    height: 50px;
    padding: 0 !important;
  }
  .block_search .form-search .in-group-btn .btn_search i {
    background-size: 52%;
    width: 50px;
    height: 50px;
  }
  .search_result .block_bg_search {
    background-color: #fff;
  }
  .search_result h3 {
    font-size: 34px;
    margin: 16px auto 18px;
    display: block;
    width: 100%;
  }
  .search_result .cartoons_content {
    padding: 13px 9px 22px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .search_result .cartoons_content .block_img_txt {
    margin: 0 10px 20px;
    width: calc(30.56103333%);
    height: 95px;
    height: 96px;
    width: 47%;
    margin: 0 1.35% 20px;
  }
  .search_result .cartoons_content .block_img_txt .left_img {
    width: 104px;
    height: 95px;
  }
  .search_result .cartoons_content .block_img_txt .right_txt {
    padding: 8px 10px 15px;
  }
  .search_result .cartoons_content .block_img_txt .right_txt h4 {
    display: block;
    font-size: 14px;
    line-height: 14px;
    max-height: 72px;
  }
  .search_result .cartoons_content .block_img_txt .right_txt h4 i {
    background-size: 49%;
    padding: 6px;
  }
  .search_result .cartoons_content .block_img_txt .right_txt span {
    font-size: 12px;
  }
  .search_result .cartoons_content .block_img_txt .left_img {
    width: 172px;
    height: 96px;
  }
  .search_result .cartoons_content .block_img_txt .left_img img {
    width: 172px;
    height: 96px;
  }
  .search_result .cartoons_content .block_img_txt .left_img.video {
    min-width: initial;
  }
  .search_result .cartoons_content .block_img_txt .right_txt {
    padding: 10px 10px 15px;
  }
  .search_result .cartoons_content .block_img_txt .right_txt h4 {
    font-family: "Life_Rg";
    max-height: 70px;
  }
  .search_result .cartoons_content .block_img_txt .right_txt span {
    margin-top: 2px;
  }
  .search_result .books_content .block_img_txt {
    height: 125px;
  }
  .search_result .books_content .block_img_txt .left_img.book,
  .search_result .books_content .block_img_txt .left_img {
    min-width: initial;
    width: 104px;
    height: 125px;
  }
  .search_result .books_content .block_img_txt .left_img.book img,
  .search_result .books_content .block_img_txt .left_img img {
    width: 104px;
    height: 125px;
  }
  .search_result .books_content .block_img_txt .right_txt span {
    margin-top: 2px;
  }
  .search_result .audiobooks_content .left_img.audiobook,
  .search_result .audiobooks_content .left_img {
    min-width: initial;
    width: 104px;
    height: 95px;
  }
  .search_result .audiobooks_content .left_img.audiobook img,
  .search_result .audiobooks_content .left_img img {
    width: 104px;
    height: 95px;
  }
  .search_result .audiobooks_content .block_img_txt .right_txt span {
    margin-top: 2px;
  }
  .search_result .games_content .left_img.games,
  .search_result .games_content .left_img {
    min-width: initial;
    width: 104px;
    height: 95px;
  }
  .search_result .games_content .left_img.games img,
  .search_result .games_content .left_img img {
    width: 104px;
    height: 95px;
  }
  .search_result .games_content .block_img_txt .right_txt span {
    margin-top: 2px;
  }
  .search_result .block_loading {
    margin: -4px 0 15px;
  }
  .search_modal {
    padding: 0 25px;
  }
  .search_modal .search {
    height: 115px;
  }
  .search_modal .form-search {
    width: 480px;
    height: 50px;
  }
  .search_modal .form-search .search_input {
    width: 426px;
    height: 50px;
    padding: 0 20px;
    font-size: 25px;
    line-height: 50px;
    color: #767676;
    border-top: 1px solid #afafaf;
    border-bottom: 1px solid #afafaf;
    border-left: 1px solid #afafaf;
    -webkit-box-shadow: inset 0 3px 7px 0 rgba(0, 0, 0, 0.12);
    box-shadow: inset 0 3px 7px 0 rgba(0, 0, 0, 0.12);
  }
  .search_modal .form-search .search_input::-webkit-input-placeholder {
    font-size: 25px;
    vertical-align: middle;
    text-align: left;
    line-height: 50px;
  }
  .search_modal .form-search .search_input:-ms-input-placeholder {
    font-size: 25px;
    vertical-align: middle;
    text-align: left;
    line-height: 50px;
  }
  .search_modal .form-search .search_input::placeholder {
    font-size: 25px;
    vertical-align: middle;
    text-align: left;
    line-height: 50px;
  }
  .search_modal .form-search .in-group-btn {
    width: 104px;
    overflow: hidden;
    margin-left: -50px;
  }
  .search_modal .form-search .in-group-btn .close-cross:before,
  .search_modal .form-search .in-group-btn .close-cross:after {
    height: 35px;
  }
  .search_modal .form-search .in-group-btn .clear_search {
    width: 54px;
    height: 50px;
    cursor: pointer;
    background: transparent;
  }
  .search_modal .form-search .in-group-btn .clear_search i {
    background: url("/images/newimg/close_search.png") no-repeat center center;
    width: 54px;
    height: 50px;
    background-size: 50%;
  }
  .search_modal .form-search .in-group-btn .btn_search {
    width: 50px;
    height: 50px;
    padding: 0 !important;
  }
  .search_modal .form-search .in-group-btn .btn_search i {
    background-size: 52%;
    width: 50px;
    height: 50px;
  }
  .search_modal h2 {
    font-size: 56px;
    margin: 80px 0 95px;
  }
  .search_modal .form-search {
    margin: 0 auto;
  }
  .search_modal .close_form {
    right: 15px;
    top: 15px;
    position: absolute;
    background: none;
    width: 36px;
    height: 36px;
    outline: none;
    cursor: pointer;
    z-index: 1;
  }
  .search_modal .close_form::before,
  .search_modal .close_form::after {
    position: absolute;
    left: 13px;
    top: 2px;
    content: ' ';
    height: 38px;
    width: 2px;
    background-color: #fff;
  }
  .search_modal .close_form::before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .search_modal .close_form::after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .search_modal .block_queries {
    padding: 0;
  }
  .search_modal .block_queries h3 {
    font-size: 34px;
    letter-spacing: -0.5px;
    margin: 43px 0 25px;
  }
  .search_modal .block_queries .btn_queries_block .btn_queries {
    font-size: 20px;
    padding: 8px 23px;
    border-radius: 3px;
  }
  /*----------------------------------------player-------------------------------------------*/
  .audiobook_player {
    top: 115px;
  }
  .audiobook_player .header.header_player {
    display: none;
  }
  .audiobook_player .audiobook_player_page {
    padding: 14px 20px;
  }
  .audiobook_player .audiobook_player_page .block_player_img {
    height: 100%;
    -webkit-box-pack: left;
    -ms-flex-pack: left;
    justify-content: left;
    width: 35%;
    float: left;
  }
  .audiobook_player .audiobook_player_page .block_player_img .bg_img_blur {
    display: none;
  }
  .audiobook_player .audiobook_player_page .block_player_img .block_player_img_small {
    width: 250px;
    height: 228px;
  }
  .audiobook_player .audiobook_player_page .block_player_img .block_player_img_small img {
    width: 100%;
    height: 100%;
  }
  .audiobook_player .audiobook_player_page .player_btn button {
    width: 67px;
    height: 67px;
  }
  .audiobook_player .audiobook_player_page .player_btn button i {
    width: 67px;
    height: 67px;
    background-size: 35px;
  }
  .audiobook_player .audiobook_player_page .player_btn button i:hover {
    background-size: 35px;
  }
  .audiobook_player .audiobook_player_page .player_btn .player_btn_play.player_btn_pause i {
    background-size: 68px;
  }
  .audiobook_player .audiobook_player_page .player_btn .player_btn_play.player_btn_pause i:hover {
    background-size: 68px;
  }
  .audiobook_player .audiobook_player_page .player_btn .player_btn_play i,
  .audiobook_player .audiobook_player_page .player_btn .player_btn_play.player_btn_pause i {
    background-size: 68px;
  }
  .audiobook_player .audiobook_player_page .player_btn .player_btn_play i:hover,
  .audiobook_player .audiobook_player_page .player_btn .player_btn_play.player_btn_pause i:hover {
    background-size: 68px;
  }
  .audiobook_player .audiobook_player_page .player_btn .player_btn_play.player_btn_pause.active i {
    background-size: 68px;
  }
  .audiobook_player .audiobook_player_page .player_btn .player_btn_play.player_btn_pause.active i:hover {
    background-size: 68px;
  }
  .audiobook_player .audiobook_player_page .player {
    margin-right: 0;
    display: inline-block;
    width: 62.2%;
  }
  .audiobook_player .audiobook_player_page .player .txt_player_name,
  .audiobook_player .audiobook_player_page .player .txt_player_author {
    display: block;
    text-align: center;
  }
  .audiobook_player .audiobook_player_page .player .txt_player_name {
    margin: 17px 0 14px;
    font: 34px/36px "Life_Rg";
  }
  .audiobook_player .audiobook_player_page .player .txt_player_author {
    font: 20px/24px "Life_Rg";
  }
  .audiobook_player .audiobook_player_page .player .player_time {
    height: 57px;
    margin: 0 17px;
  }
  .audiobook_player .audiobook_player_page .player .player_time .left-time,
  .audiobook_player .audiobook_player_page .player .player_time .right-time {
    font-size: 22px;
  }
  .audiobook_player .audiobook_player_page .player .player_time .progress-holder {
    height: 4px;
    margin: 25px;
  }
  .audiobook_player .audiobook_player_page .player .player_time .line {
    height: 4px;
  }
  .audiobook_player .audiobook_player_page .player .player_time .line::before {
    width: 27px;
    height: 27px;
    margin: -13px -3px;
  }
  .audiobook_player .audiobook_player_page .player .player_time .progress-bar {
    height: 4px;
  }
  .audiobook_player .audiobook_player_page .player_list {
    margin: 22px auto 30px;
    width: 100%;
  }
  .audiobook_player .audiobook_player_page .player_list ul li {
    height: 84px;
  }
  .audiobook_player .audiobook_player_page .player_list ul li a {
    font-size: 22px;
    line-height: 28px;
    height: 84px;
    padding: 0 24px;
  }
  .audiobook_player .audiobook_player_page .player_list ul li a span:first-child {
    width: 84%;
  }
  .audiobook_player .audiobook_player_page .player_list ul li a .icon_list_player {
    background-size: 18px;
    width: 28px;
    height: 24px;
  }
  .audiobook_player .audiobook_player_page .player_list ul li a:hover .icon_list_player {
    background-size: 18px;
    width: 28px;
    height: 24px;
  }
  .audiobook_player .audiobook_player_page .player_list ul li.active a .icon_list_player {
    background-size: 58%;
    width: 28px;
    height: 23px;
  }
  body.no-scroll {
    height: 100%;
    width: 100%;
    position: fixed;
    overflow: hidden;
  }
  /*---------------------------------------404--------------------------------------------*/
  .block_404 {
    top: 60px;
    background: url("/images/newimg/404_tablet.jpg") no-repeat center center;
    background-size: cover;
  }
  .block_404 .box_404 h2 {
    font-size: 291.36px;
    line-height: 0.79;
  }
  .block_404 .box_404 span {
    font-family: "Life_Lt";
    font-size: 54.02px;
    letter-spacing: -1px;
  }
}
/*-------------------------------------------mixin-------------------------------------------*/
.header_link_lang {
  display: none;
}
@media screen and (min-width: 1200px) {
  .wrapper {
    max-width: 2560px;
  }
  .container {
    max-width: 1182px;
  }
  /*------------------------------------header--------------------------------------------*/
  .header {
    height: 72px;
  }
  .header_link_lang {
    height: 29px;
    background-color: #4b4d4e;
    display: block;
  }
  .header_link_lang form {
    margin-top: 35px;
  }
  .header_link_lang .container > .row {
    height: 29px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    float: right;
    position: relative;
  }
  .header_link_lang .btn_link {
    margin-right: 30px;
  }
  .header_link_lang .btn_lang {
    position: relative;
    z-index: 9999;
    cursor: pointer;
    padding-right: 24px;
  }
  .header_link_lang .btn_lang i {
    background: url("/images/newimg/rectangle.png") no-repeat center center;
    width: 21px;
    height: 14px;
    position: absolute;
    top: 4px;
    right: -1px;
    background-size: 14px;
  }
  .header_link_lang .btn_lang .transform_i {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  .header_link_lang a {
    color: #fff;
    font-size: 16px;
  }
  .header_link_lang .menu_popup_lang {
    position: absolute;
    top: 0;
    right: -7px;
    width: 210px;
    min-height: 138px;
    background-color: #000;
    opacity: .9;
    z-index: 999;
  }
  .header_link_lang .menu_popup_lang .radio_sprite {
    display: none;
  }
  .header_link_lang .menu_popup_lang .radio_sprite:checked + label::before {
    background-position: -1px -36px;
  }
  .header_link_lang .menu_popup_lang label {
    cursor: pointer;
    background-position: 0 10px;
    line-height: 22px;
    font-weight: 400;
    margin-left: 5px;
    color: #fff;
    font-size: 16px;
    padding: 12px 0px;
  }
  .header_link_lang .menu_popup_lang label:before {
    content: "";
    display: inline-block;
    float: left;
    background: url("/images/newimg/radio.png") no-repeat;
    height: 20px;
    width: 25px;
    background-position: -1px 1px;
    background-size: 73%;
  }
  .header_btn {
    margin-right: 0;
  }
  .header_btn_search {
    background-color: transparent;
    width: 50px;
    height: 50px;
  }
  .header_btn_search i {
    background-size: 32px;
  }
  .header_btn_user {
    background-color: transparent;
    width: 50px;
    height: 50px;
    margin-left: 20px;
  }
  .header_btn_user i {
    background-size: 32px;
  }
  .header_btn_nav {
    background-color: transparent;
    width: 50px;
    height: 50px;
    margin-left: 20px;
    margin-right: -7px;
  }
  .header_btn_nav i {
    left: 10px;
    top: 12px;
    height: 20px;
    width: 26px;
  }
  .header_btn_nav i::before,
  .header_btn_nav i::after {
    width: 26px;
    left: 0;
    height: 2px;
  }
  .header_btn_nav i::before {
    top: 4px;
  }
  .header_btn_nav i::after {
    top: 11px;
  }
  .header_menu + .container > .row {
    height: 72px;
  }
  /*----------------------------------------footer--------------------------------------------*/
  .footer {
    height: 100%;
    padding-top: 58px;
  }
  .footer > .col-xs-12 {
    max-width: 1220px;
    margin: 0 auto;
    float: initial;
  }
  .footer span {
    display: block;
    width: 100%;
    text-align: left;
    font-size: 24px;
    line-height: 36px;
    margin-left: 22px;
    letter-spacing: 0.1px;
    margin-bottom: 38px;
  }
  .footer .footer_btn {
    width: 100%;
    float: left;
  }
  .footer .footer_btn a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 28px;
    border-bottom: 2px solid #002395;
    text-indent: 15px;
    padding: 10px 20px;
  }
  .footer .footer_btn a:after {
    display: block;
    right: 5px;
    top: 21px;
    background-size: 14px;
  }
  .footer .footer_btn a:first-child {
    border-top: 2px solid #002395;
  }
  .footer .footer_btn a + a {
    margin-left: 0;
  }
  .footer .footer_btn a + a::before {
    display: none;
  }
  .footer .copyright {
    width: 100%;
    float: left;
  }
  .footer .copyright span {
    font-size: 20px;
    margin-top: 60px;
  }
  /*-----------------------------------------content--------------------------------------------*/
  .home_menu {
    height: 148px;
  }
  .home_menu ul {
    width: 804px;
  }
  .home_menu ul li {
    height: 132px;
  }
  .home_menu ul li a {
    font-size: 17.16px;
    line-height: 182px;
  }
  .home_menu ul li .home_menu_icon_1:before {
    background-size: 48px;
    top: -4px;
  }
  .home_menu ul li .home_menu_icon_2:before {
    background-size: 40px;
    top: -3px;
  }
  .home_menu ul li .home_menu_icon_3:before {
    background-size: 48px;
    top: -7px;
  }
  .home_menu ul li .home_menu_icon_4:before {
    background-size: 48px;
    top: -3px;
  }
  .home_banner_container {
    height: auto;
  }
  .home_banner_slide {
    height: auto;
  }
  .home_banner_slide h2 {
    top: 9%;
  }
  .home_banner_slide._cartoons {
    background: url(/images/newimg/top_banners/banner_home_page_decktop.png) no-repeat center bottom;
    background-size: cover;
  }
  .home_banner_slide_btn {
    width: 244px;
    height: 50px;
    font-size: 24px;
    margin-left: -122px;
    top: 63%;
  }
  .btn_prev {
    background: url("/images/newimg/btn_prev.png") no-repeat center center;
    width: 16px;
    height: 30px;
  }
  .btn_next {
    background: url("/images/newimg/btn_next.png") no-repeat center center;
    width: 16px;
    height: 30px;
  }
  .txt_slider,
  .slider_1.owl-carousel,
  .slider_2.owl-carousel {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
  }
  .txt_slider {
    margin-top: 50px;
    margin-bottom: 30px;
  }
  .txt_slider h3 {
    font-size: 36px;
    padding-left: 8px;
  }
  .txt_slider a {
    font-size: 22px;
    padding: 10px 11px 0 0;
    font-family: "Life_Rg";
  }
  .home_menu + .col-xs-12 .txt_slider {
    margin-top: 54px;
  }
  .slider_1.owl-carousel .owl-nav.disabled .owl-prev,
  .slider_2.owl-carousel .owl-nav.disabled .owl-prev,
  .slider_1.owl-carousel .owl-nav .owl-prev,
  .slider_2.owl-carousel .owl-nav .owl-prev {
    background: url("/images/newimg/btn_prev.png") no-repeat center center;
  }
  .slider_1.owl-carousel .owl-nav.disabled .owl-next,
  .slider_2.owl-carousel .owl-nav.disabled .owl-next,
  .slider_1.owl-carousel .owl-nav .owl-next,
  .slider_2.owl-carousel .owl-nav .owl-next {
    background: url("/images/newimg/btn_next.png") no-repeat center center;
  }
  .slider_1.owl-carousel .owl-nav.disabled .owl-prev,
  .slider_2.owl-carousel .owl-nav.disabled .owl-prev,
  .slider_1.owl-carousel .owl-nav .owl-prev,
  .slider_2.owl-carousel .owl-nav .owl-prev,
  .slider_1.owl-carousel .owl-nav.disabled .owl-next,
  .slider_2.owl-carousel .owl-nav.disabled .owl-next,
  .slider_1.owl-carousel .owl-nav .owl-next,
  .slider_2.owl-carousel .owl-nav .owl-next {
    width: 16px;
    height: 30px;
    position: absolute;
  }
  .slider_1 {
    padding: 0;
  }
  .slider_1 .owl-nav.disabled .owl-prev,
  .slider_1 .owl-nav .owl-prev {
    left: -35px;
  }
  .slider_1 .owl-nav.disabled .owl-next,
  .slider_1 .owl-nav .owl-next {
    right: -33px;
  }
  .slider_1 .owl-nav.disabled .owl-prev,
  .slider_1 .owl-nav .owl-prev,
  .slider_1 .owl-nav.disabled .owl-next,
  .slider_1 .owl-nav .owl-next {
    top: 97px;
  }
  .slider_1 .owl-item:first-child {
    margin-left: -13px;
  }
  .slider_1 .item {
    margin-left: 21px;
    max-width: 380px;
    width: 100%;
  }
  .slider_1 a img {
    width: 380px !important;
    height: 213px;
  }
  .slider_1 a h4 {
    font-size: 24px;
    line-height: 24px;
    font-family: "Life_Lt";
    margin: 17px 0 70px;
  }
  .slider_2 .label_box,
  .slider_2 .label_box_free {
    width: 64px;
    height: 36px;
    background: url("/images/newimg/ribbon_decktop.png") no-repeat center center;
    left: -7px;
    top: -8px;
  }
  .slider_2 .label_box.top,
  .slider_2 .label_box_free.top {
    top: 30px;
  }
  .slider_2 .label_box_free {
    left: -10px;
    width: 125px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: url("/images/newimg/ribbon_free.png") no-repeat center center;
    background-size: 100%;
  }
  .slider_2 .owl-nav.disabled .owl-prev,
  .slider_2 .owl-nav .owl-prev {
    left: -35px;
  }
  .slider_2 .owl-nav.disabled .owl-next,
  .slider_2 .owl-nav .owl-next {
    right: -33px;
  }
  .slider_2 .owl-nav.disabled .owl-prev,
  .slider_2 .owl-nav .owl-prev,
  .slider_2 .owl-nav.disabled .owl-next,
  .slider_2 .owl-nav .owl-next {
    top: 50%;
    margin-top: -33px;
  }
  .slider_2 .owl-item:first-child {
    margin-left: -14px;
  }
  .slider_2 .cartoons_slide {
    width: 281px;
    height: 317px;
    margin-left: 20px;
    margin-bottom: 58px;
  }
  .slider_2 .cartoons_slide img {
    width: 281px;
    height: 189px;
  }
  .slider_2 .cartoons_slide h4 {
    font-size: 24px;
    line-height: 30px;
    max-height: 75px;
    width: 100%;
    padding: 17px 21px 0 21px;
  }
  .slider_2 .cartoons_slide h4 i {
    background-size: 12px;
    margin-left: -4px;
  }
  .slider_2 .cartoons_slide span {
    font-size: 18px;
    max-height: 35px;
    padding: 0 21px 13px 21px;
  }
  .slider_2 .cartoons_slide .label_text,
  .slider_2 .cartoons_slide .label_text_free {
    margin: 6px 0;
    font: 14px "Life_B";
    text-transform: uppercase;
  }
  .slider_2 .cartoons_slide .label_text_free {
    width: auto;
  }
  .slider_2 .books_slide {
    width: 581px;
    height: 222px;
    margin-left: 20.57142857px;
    margin-bottom: 58px;
  }
  .slider_2 .books_slide img {
    width: 160px;
    height: 222px;
    float: left;
  }
  .slider_2 .books_slide h4,
  .slider_2 .books_slide span {
    width: calc(100% - 161px);
    width: -webkit-calc(100% - 161px);
  }
  .slider_2 .books_slide h4 {
    font-size: 24px;
    line-height: 30px;
    padding: 24px 21px 0 21px;
    max-height: 85px;
  }
  .slider_2 .books_slide h4 i {
    background-size: 12px;
    margin-left: -4px;
    padding-top: 18px;
  }
  .slider_2 .books_slide span {
    font-size: 18px;
    max-height: 35px;
    padding: 0 21px 13px 21px;
  }
  .slider_2 .books_slide .label_text,
  .slider_2 .books_slide .label_text_free {
    margin: 6px 0;
    font: 14px "Life_B";
    text-transform: uppercase;
  }
  .slider_2 .books_slide .label_text_free {
    width: auto;
  }
  .slider_2 .books_slide.game_slide {
    width: 581px;
    height: 222px;
    margin-left: 20.57142857px;
    margin-bottom: 58px;
    height: 194px;
  }
  .slider_2 .books_slide.game_slide img {
    width: 160px;
    height: 222px;
    float: left;
  }
  .slider_2 .books_slide.game_slide h4,
  .slider_2 .books_slide.game_slide span {
    width: calc(100% - 161px);
    width: -webkit-calc(100% - 161px);
  }
  .slider_2 .books_slide.game_slide h4 {
    font-size: 24px;
    line-height: 30px;
    padding: 24px 21px 0 21px;
    max-height: 85px;
  }
  .slider_2 .books_slide.game_slide h4 i {
    background-size: 12px;
    margin-left: -4px;
    padding-top: 18px;
  }
  .slider_2 .books_slide.game_slide span {
    font-size: 18px;
    max-height: 35px;
    padding: 0 21px 13px 21px;
  }
  .slider_2 .books_slide.game_slide .label_text,
  .slider_2 .books_slide.game_slide .label_text_free {
    margin: 6px 0;
    font: 14px "Life_B";
    text-transform: uppercase;
  }
  .slider_2 .books_slide.game_slide .label_text_free {
    width: auto;
  }
  .slider_2 .books_slide.game_slide img {
    height: 194px;
  }
  .slider_2 .audiobooks_slide.game_slide,
  .slider_2 .games_slide.game_slide {
    width: 581px;
    height: 222px;
    margin-left: 20.57142857px;
    margin-bottom: 58px;
    height: 161px;
  }
  .slider_2 .audiobooks_slide.game_slide img,
  .slider_2 .games_slide.game_slide img {
    width: 160px;
    height: 222px;
    float: left;
  }
  .slider_2 .audiobooks_slide.game_slide h4,
  .slider_2 .games_slide.game_slide h4,
  .slider_2 .audiobooks_slide.game_slide span,
  .slider_2 .games_slide.game_slide span {
    width: calc(100% - 161px);
    width: -webkit-calc(100% - 161px);
  }
  .slider_2 .audiobooks_slide.game_slide h4,
  .slider_2 .games_slide.game_slide h4 {
    font-size: 24px;
    line-height: 30px;
    padding: 24px 21px 0 21px;
    max-height: 85px;
  }
  .slider_2 .audiobooks_slide.game_slide h4 i,
  .slider_2 .games_slide.game_slide h4 i {
    background-size: 12px;
    margin-left: -4px;
    padding-top: 18px;
  }
  .slider_2 .audiobooks_slide.game_slide span,
  .slider_2 .games_slide.game_slide span {
    font-size: 18px;
    max-height: 35px;
    padding: 0 21px 13px 21px;
  }
  .slider_2 .audiobooks_slide.game_slide .label_text,
  .slider_2 .games_slide.game_slide .label_text,
  .slider_2 .audiobooks_slide.game_slide .label_text_free,
  .slider_2 .games_slide.game_slide .label_text_free {
    margin: 6px 0;
    font: 14px "Life_B";
    text-transform: uppercase;
  }
  .slider_2 .audiobooks_slide.game_slide .label_text_free,
  .slider_2 .games_slide.game_slide .label_text_free {
    width: auto;
  }
  .slider_2 .audiobooks_slide.game_slide img,
  .slider_2 .games_slide.game_slide img {
    height: 161px;
  }
  .slider_2 .audiobooks_slide {
    width: 581px;
    height: 222px;
    margin-left: 20.57142857px;
    margin-bottom: 58px;
    height: 161px;
  }
  .slider_2 .audiobooks_slide img {
    width: 160px;
    height: 222px;
    float: left;
  }
  .slider_2 .audiobooks_slide h4,
  .slider_2 .audiobooks_slide span {
    width: calc(100% - 161px);
    width: -webkit-calc(100% - 161px);
  }
  .slider_2 .audiobooks_slide h4 {
    font-size: 24px;
    line-height: 30px;
    padding: 24px 21px 0 21px;
    max-height: 85px;
  }
  .slider_2 .audiobooks_slide h4 i {
    background-size: 12px;
    margin-left: -4px;
    padding-top: 18px;
  }
  .slider_2 .audiobooks_slide span {
    font-size: 18px;
    max-height: 35px;
    padding: 0 21px 13px 21px;
  }
  .slider_2 .audiobooks_slide .label_text,
  .slider_2 .audiobooks_slide .label_text_free {
    margin: 6px 0;
    font: 14px "Life_B";
    text-transform: uppercase;
  }
  .slider_2 .audiobooks_slide .label_text_free {
    width: auto;
  }
  .slider_2 .audiobooks_slide img {
    height: 161px;
    width: 161px;
  }
  .slider_2 .games_slide {
    width: 581px;
    height: 222px;
    margin-left: 20.57142857px;
    margin-bottom: 58px;
    height: 161px;
  }
  .slider_2 .games_slide img {
    width: 160px;
    height: 222px;
    float: left;
  }
  .slider_2 .games_slide h4,
  .slider_2 .games_slide span {
    width: calc(100% - 161px);
    width: -webkit-calc(100% - 161px);
  }
  .slider_2 .games_slide h4 {
    font-size: 24px;
    line-height: 30px;
    padding: 24px 21px 0 21px;
    max-height: 85px;
  }
  .slider_2 .games_slide h4 i {
    background-size: 12px;
    margin-left: -4px;
    padding-top: 18px;
  }
  .slider_2 .games_slide span {
    font-size: 18px;
    max-height: 35px;
    padding: 0 21px 13px 21px;
  }
  .slider_2 .games_slide .label_text,
  .slider_2 .games_slide .label_text_free {
    margin: 6px 0;
    font: 14px "Life_B";
    text-transform: uppercase;
  }
  .slider_2 .games_slide .label_text_free {
    width: auto;
  }
  .slider_2 .games_slide img {
    height: 161px;
    width: 161px;
  }
  .category_title {
    background-size: cover;
  }
  .category_title h2 {
    font-size: 64px;
  }
  .breadcrumbs {
    padding: 14.5px 22px 13px;
    background-color: #fff;
  }
  .reverse_color_bg .home_menu + .col-xs-12 .txt_slider {
    margin-top: 54px;
  }
  /*------------------------------------cartoon--------------------------------------------*/
  .cartoons_content.cartoons_content_2,
  .cartoons_content.cartoon_content_1,
  .cartoons_content.cartoons_content_1 {
    padding: 20px 11px 40px;
  }
  .cartoons_content.cartoons_content_2 .container > .row,
  .cartoons_content.cartoon_content_1 .container > .row,
  .cartoons_content.cartoons_content_1 .container > .row {
    margin-left: -25px;
    margin-right: -25px;
  }
  .cartoons_content.cartoons_content_2 .bottom_txt a h4 {
    font-size: 24px;
    line-height: 30px;
    max-height: 75px;
    padding: 13px 16px 0 16px;
  }
  .cartoons_content.cartoons_content_2 .bottom_txt a span {
    padding: 7px 16px 13px 16px;
  }
  .block_img_txt_2 {
    width: calc(30.56103333%);
    margin: 0 10px 20px;
  }
  .block_img_txt_2 .top_img {
    height: 213px;
  }
  .block_img_txt_2 .bottom_txt {
    height: 123px;
  }
  .block_img_txt_2 .bottom_txt h4 {
    font-size: 24px;
    line-height: 30px;
    max-height: 75px;
    padding: 13px 16px 0 16px;
  }
  .block_img_txt_2 .bottom_txt span {
    font-size: 18px;
    padding: 7px 16px 13px 16px;
  }
  .cartoons_content.cartoons_content_1 .ac-container.ac-collapse {
    width: 380px;
    margin: -2px auto 22px;
    float: inherit;
    display: table;
  }
  .cartoons_content.cartoons_content_1 .block_img_txt {
    margin: 0 10px 20px;
    height: 100%;
    width: 280px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .cartoons_content.cartoons_content_1 .block_img_txt .left_img,
  .cartoons_content.cartoons_content_1 .block_img_txt .left_img.video {
    width: 280px;
    height: 188px;
  }
  .cartoons_content.cartoons_content_1 .block_img_txt .left_img img,
  .cartoons_content.cartoons_content_1 .block_img_txt .left_img.video img {
    width: 100%;
    height: 100%;
  }
  .cartoons_content.cartoons_content_1 .block_img_txt .right_txt {
    height: 128px;
  }
  .cartoons_content {
    padding: 20px 22px 22px;
  }
  .cartoons_content .cartoons_img.unauthorized::after {
    background-size: cover;
    background: url("/images/newimg/Play-arrow-overlay_decktop.png") no-repeat center center;
    width: 128px;
    height: 128px;
    margin-top: -94px;
  }
  .cartoons_content h2 {
    font: 36px/48px "Life_Lt";
  }
  .cartoons_content p {
    font: 24px/24px "Life_B";
    margin-bottom: 20px;
  }
  .cartoons_content .info_content span {
    font-size: 20px;
  }
  .cartoons_content .ac-container.ac-collapse {
    margin: 47px 0 42px;
    min-height: 93px;
  }
  .cartoons_content .ac-container.ac-collapse #toggle_1 p {
    margin: 0 23px !important;
    font-family: "Life_Rg";
    padding: 31px 0 34px !important;
  }
  .cartoons_content .ac-container.ac-collapse span {
    line-height: 93px;
    font-size: 22px;
    padding-left: 40px;
  }
  .cartoons_content .ac-container.ac-collapse::before {
    top: 27px;
    background-size: 13px;
    right: 45px;
  }
  .content .col-xs-12 + .bg_block_decktop {
    background-color: #ebebeb;
  }
  /*------------------------------------books------------------------------------------------*/
  .books_content {
    padding: 20px 11px 40px;
  }
  .books_content .container > .row {
    margin-left: -25px;
    margin-right: -25px;
  }
  .books_content .block_img_txt {
    margin: 0 10px 20px;
    width: 48.33185%;
    height: 194px;
  }
  .books_content .block_img_txt .left_img {
    width: 161px;
    height: 194px;
  }
  .books_content .block_img_txt .left_img img {
    width: 100%;
  }
  .books_content .block_img_txt .right_txt {
    width: calc(100% - 161px);
    width: -webkit-calc(100% - 161px);
    padding: 24px 21px 15px;
  }
  .books_content .block_img_txt .right_txt h4 {
    font-size: 24px;
    line-height: 30px;
    max-height: 65px;
  }
  .books_content .block_img_txt .right_txt h4 i {
    background-size: 12px;
    padding: 16px;
    position: relative;
    top: 3px;
  }
  .books_content .block_img_txt .right_txt span {
    font-size: 18px;
  }
  .books_content .block_img_txt.block_img_txt_3 {
    margin: 0 10px;
  }
  .books_content .block_img_txt.block_img_txt_3 .left_img {
    width: 244px;
    height: 341px;
  }
  .books_content .block_img_txt.block_img_txt_3 .left_img img {
    width: 100%;
    height: 100%;
  }
  .books_content .block_img_txt.block_img_txt_3 .right_txt {
    padding: 14px 70px 14px 42px;
  }
  .books_content .block_img_txt.block_img_txt_3 .right_txt h4 {
    font-size: 40px;
    line-height: 48px;
    max-height: 100px;
    margin-bottom: 23px;
  }
  .books_content .block_img_txt.block_img_txt_3 .right_txt span {
    font-size: 20px;
  }
  .books_content .block_img_txt.block_img_txt_3 .right_txt .name_author {
    display: block;
    margin-bottom: 1px;
  }
  .books_content .block_img_txt.block_img_txt_3 .right_txt .time_audio {
    margin-top: 6px;
  }
  .books_content .read_more {
    width: 215px;
    line-height: 49px;
    font-size: 20px;
    position: absolute;
    left: 295px;
    top: 147px;
    width: 300px;
    height: 50px;
    top: 242px;
    left: calc(50% - 305px);
    left: -webkit-calc(50% - 305px);
  }
  .books_content .ac-container.ac-collapse {
    margin: 47px 0 42px;
    min-height: 93px;
    width: calc(100% - 20px);
    width: -webkit-calc(100% - 20px);
    margin-left: 10px;
  }
  .books_content .ac-container.ac-collapse #toggle_1 p {
    margin: 0 23px !important;
    font-family: "Life_Rg";
    padding: 31px 0 34px !important;
  }
  .books_content .ac-container.ac-collapse span {
    line-height: 93px;
    font-size: 22px;
    padding-left: 40px;
  }
  .books_content .ac-container.ac-collapse::before {
    top: 27px;
    background-size: 13px;
    right: 45px;
  }
  /*------------------------------------audiobooks------------------------------------------------*/
  .audiobooks_content {
    padding: 20px 11px 40px;
  }
  .audiobooks_content .container > .row {
    margin-left: -25px;
    margin-right: -25px;
  }
  .audiobooks_content .block_img_txt {
    margin: 0 10px 20px;
    width: 48.33185%;
    height: 194px;
  }
  .audiobooks_content .block_img_txt .left_img {
    width: 161px;
    height: 194px;
  }
  .audiobooks_content .block_img_txt .left_img img {
    width: 100%;
  }
  .audiobooks_content .block_img_txt .right_txt {
    width: calc(100% - 161px);
    width: -webkit-calc(100% - 161px);
    padding: 24px 21px 15px;
  }
  .audiobooks_content .block_img_txt .right_txt h4 {
    font-size: 24px;
    line-height: 30px;
    max-height: 65px;
  }
  .audiobooks_content .block_img_txt .right_txt h4 i {
    background-size: 12px;
    padding: 16px;
    position: relative;
    top: 3px;
  }
  .audiobooks_content .block_img_txt .right_txt span {
    font-size: 18px;
  }
  .audiobooks_content .block_img_txt.block_img_txt_3 {
    margin: 0 10px;
  }
  .audiobooks_content .block_img_txt.block_img_txt_3 .left_img {
    width: 244px;
    height: 341px;
  }
  .audiobooks_content .block_img_txt.block_img_txt_3 .left_img img {
    width: 100%;
    height: 100%;
  }
  .audiobooks_content .block_img_txt.block_img_txt_3 .right_txt {
    padding: 14px 70px 14px 42px;
  }
  .audiobooks_content .block_img_txt.block_img_txt_3 .right_txt h4 {
    font-size: 40px;
    line-height: 48px;
    max-height: 100px;
    margin-bottom: 23px;
  }
  .audiobooks_content .block_img_txt.block_img_txt_3 .right_txt span {
    font-size: 20px;
  }
  .audiobooks_content .block_img_txt.block_img_txt_3 .right_txt .name_author {
    display: block;
    margin-bottom: 1px;
  }
  .audiobooks_content .block_img_txt.block_img_txt_3 .right_txt .time_audio {
    margin-top: 6px;
  }
  .audiobooks_content .read_more {
    width: 215px;
    line-height: 49px;
    font-size: 20px;
    position: absolute;
    left: 295px;
    top: 147px;
    width: 300px;
    height: 50px;
    top: 242px;
    left: calc(50% - 305px);
    left: -webkit-calc(50% - 305px);
  }
  .audiobooks_content .ac-container.ac-collapse {
    margin: 47px 0 42px;
    min-height: 93px;
    width: calc(100% - 20px);
    width: -webkit-calc(100% - 20px);
    margin-left: 10px;
  }
  .audiobooks_content .ac-container.ac-collapse #toggle_1 p {
    margin: 0 23px !important;
    font-family: "Life_Rg";
    padding: 31px 0 34px !important;
  }
  .audiobooks_content .ac-container.ac-collapse span {
    line-height: 93px;
    font-size: 22px;
    padding-left: 40px;
  }
  .audiobooks_content .ac-container.ac-collapse::before {
    top: 27px;
    background-size: 13px;
    right: 45px;
  }
  .audiobooks_content .block_img_txt {
    margin: 0 10px 20px;
    width: 48.33185%;
    height: 194px;
    height: 161px;
  }
  .audiobooks_content .block_img_txt .left_img {
    width: 161px;
    height: 194px;
  }
  .audiobooks_content .block_img_txt .left_img img {
    width: 100%;
  }
  .audiobooks_content .block_img_txt .right_txt {
    width: calc(100% - 161px);
    width: -webkit-calc(100% - 161px);
    padding: 24px 21px 15px;
  }
  .audiobooks_content .block_img_txt .right_txt h4 {
    font-size: 24px;
    line-height: 30px;
    max-height: 65px;
  }
  .audiobooks_content .block_img_txt .right_txt h4 i {
    background-size: 12px;
    padding: 16px;
    position: relative;
    top: 3px;
  }
  .audiobooks_content .block_img_txt .right_txt span {
    font-size: 18px;
  }
  .audiobooks_content .block_img_txt .left_img {
    height: 161px;
  }
  .audiobooks_content .block_img_txt.block_img_txt_3 {
    margin: 0 10px;
  }
  .audiobooks_content .block_img_txt.block_img_txt_3 .left_img {
    width: 244px;
    height: 341px;
  }
  .audiobooks_content .block_img_txt.block_img_txt_3 .left_img img {
    width: 100%;
    height: 100%;
  }
  .audiobooks_content .block_img_txt.block_img_txt_3 .right_txt {
    padding: 14px 70px 14px 42px;
  }
  .audiobooks_content .block_img_txt.block_img_txt_3 .right_txt h4 {
    font-size: 40px;
    line-height: 48px;
    max-height: 100px;
    margin-bottom: 23px;
  }
  .audiobooks_content .block_img_txt.block_img_txt_3 .right_txt span {
    font-size: 20px;
  }
  .audiobooks_content .block_img_txt.block_img_txt_3 .right_txt .name_author {
    display: block;
    margin-bottom: 1px;
  }
  .audiobooks_content .block_img_txt.block_img_txt_3 .right_txt .time_audio {
    margin-top: 6px;
  }
  .audiobooks_content .block_img_txt.block_img_txt_3 .left_img {
    width: 341px;
  }
  .audiobooks_content .block_img_txt.block_img_txt_3 .right_txt {
    width: calc(-241%);
    width: -webkit-calc(-241%);
  }
  .audiobooks_content .block_img_txt.block_img_txt_3 .right_txt .time_audio {
    margin-top: 0;
    color: #333;
  }
  .audiobooks_content .read_more {
    left: calc(50% - 207px);
    left: -webkit-calc(50% - 207px);
  }
  .category_title_tablet {
    display: block;
  }
  /*----------------------------------------player-------------------------------------------*/
  .audiobook_player {
    -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
    right: 30px;
    width: 385px;
    position: absolute;
    top: 432px;
    height: inherit;
    left: inherit;
    bottom: inherit;
    overflow: inherit;
  }
  .audiobook_player .header.header_player {
    display: block;
    background: transparent;
    position: absolute;
  }
  .audiobook_player .header.header_player .header_logo {
    display: none;
  }
  .audiobook_player .header.header_player .header_btn {
    position: absolute;
    right: 9px;
    top: 4px;
  }
  .audiobook_player .header.header_player .header_btn .header_btn_up {
    width: 36px;
    height: 36px;
    background-color: transparent;
  }
  .audiobook_player .header.header_player .header_btn .header_btn_up i {
    background: none;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 36px;
    height: 36px;
    outline: none;
    cursor: pointer;
    border-bottom: none;
    z-index: 1;
  }
  .audiobook_player .header.header_player .header_btn .header_btn_up i::before,
  .audiobook_player .header.header_player .header_btn .header_btn_up i::after {
    position: absolute;
    content: ' ';
    height: 36px;
    width: 2px;
    background-color: #fff;
  }
  .audiobook_player .header.header_player .header_btn .header_btn_up i::before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .audiobook_player .header.header_player .header_btn .header_btn_up i::after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .audiobook_player .audiobook_player_page {
    padding: 0;
    min-height: inherit;
  }
  .audiobook_player .audiobook_player_page .block_player_img {
    height: 270px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    float: inherit;
  }
  .audiobook_player .audiobook_player_page .block_player_img .bg_img_blur {
    display: block;
  }
  .audiobook_player .audiobook_player_page .block_player_img .block_player_img_small {
    width: 150px;
    height: 138px;
  }
  .audiobook_player .audiobook_player_page .block_player_img .block_player_img_small img {
    width: 100%;
    height: 100%;
  }
  .audiobook_player .audiobook_player_page .player_btn {
    margin: 9px auto 0px;
  }
  .audiobook_player .audiobook_player_page .player_btn button {
    width: 59px;
    height: 59px;
  }
  .audiobook_player .audiobook_player_page .player_btn button i {
    width: 59px;
    height: 59px;
    background-size: 33px;
  }
  .audiobook_player .audiobook_player_page .player_btn button i:hover {
    background-size: 33px;
  }
  .audiobook_player .audiobook_player_page .player_btn .player_btn_play.player_btn_pause i {
    left: 1px;
  }
  .audiobook_player .audiobook_player_page .player_btn .player_btn_play i,
  .audiobook_player .audiobook_player_page .player_btn .player_btn_play.player_btn_pause i {
    background-size: 59px;
  }
  .audiobook_player .audiobook_player_page .player_btn .player_btn_play i:hover,
  .audiobook_player .audiobook_player_page .player_btn .player_btn_play.player_btn_pause i:hover {
    background-size: 59px;
  }
  .audiobook_player .audiobook_player_page .player_btn .player_btn_play.player_btn_pause.active i {
    background-size: 59px;
  }
  .audiobook_player .audiobook_player_page .player_btn .player_btn_play.player_btn_pause.active i:hover {
    background-size: 59px;
  }
  .audiobook_player .audiobook_player_page .player {
    width: 100%;
    margin: 0;
  }
  .audiobook_player .audiobook_player_page .player .txt_player_name,
  .audiobook_player .audiobook_player_page .player .txt_player_author {
    display: none;
  }
  .audiobook_player .audiobook_player_page .player .player_time {
    margin: 0 10px;
  }
  .audiobook_player .audiobook_player_page .player .player_time .left-time,
  .audiobook_player .audiobook_player_page .player .player_time .right-time {
    font-size: 19.2px;
  }
  .audiobook_player .audiobook_player_page .player .player_time .line::before {
    width: 25px;
    height: 25px;
    margin: -11px -3px;
  }
  .audiobook_player .audiobook_player_page .player .player_time .progress-bar {
    height: 4px;
  }
  .audiobook_player .audiobook_player_page .player_list {
    margin: 22px auto 15px;
    width: 93%;
    height: 375px;
  }
  .audiobook_player .audiobook_player_page .player_list .simplebar-scrollbar {
    background: #002395;
  }
  .audiobook_player .audiobook_player_page .player_list .simplebar-scrollbar.visible {
    opacity: 1;
  }
  .audiobook_player .audiobook_player_page .player_list ul li {
    height: 75px;
  }
  .audiobook_player .audiobook_player_page .player_list ul li a {
    font-size: 19.2px;
    height: 75px;
    padding: 0 16px;
  }
  .audiobook_player .audiobook_player_page .player_list ul li a span {
    line-height: 23px !important;
  }
  .audiobook_player .audiobook_player_page .player_list ul li a .time_list {
    margin-right: 17px;
  }
  .audiobook_player .audiobook_player_page .player_list ul li a .icon_list_player {
    background-size: 15px;
  }
  .audiobook_player .audiobook_player_page .player_list ul li a .icon_list_player:hover {
    background-size: 15px !important;
  }
  .audiobook_player .audiobook_player_page .player_list ul li a:hover .icon_list_player {
    background-size: 15px !important;
  }
  .audiobook_player .audiobook_player_page .player_list ul li.active a .icon_list_player {
    background-size: 15px !important;
  }
  /*------------------------------------audiobooks------------------------------------------------*/
  .games_content {
    padding: 20px 11px 40px;
  }
  .games_content .container > .row {
    margin-left: -25px;
    margin-right: -25px;
  }
  .games_content .block_img_txt {
    margin: 0 10px 20px;
    width: 48.33185%;
    height: 194px;
  }
  .games_content .block_img_txt .left_img {
    width: 161px;
    height: 194px;
  }
  .games_content .block_img_txt .left_img img {
    width: 100%;
  }
  .games_content .block_img_txt .right_txt {
    width: calc(100% - 161px);
    width: -webkit-calc(100% - 161px);
    padding: 24px 21px 15px;
  }
  .games_content .block_img_txt .right_txt h4 {
    font-size: 24px;
    line-height: 30px;
    max-height: 65px;
  }
  .games_content .block_img_txt .right_txt h4 i {
    background-size: 12px;
    padding: 16px;
    position: relative;
    top: 3px;
  }
  .games_content .block_img_txt .right_txt span {
    font-size: 18px;
  }
  .games_content .block_img_txt.block_img_txt_3 {
    margin: 0 10px;
  }
  .games_content .block_img_txt.block_img_txt_3 .left_img {
    width: 244px;
    height: 341px;
  }
  .games_content .block_img_txt.block_img_txt_3 .left_img img {
    width: 100%;
    height: 100%;
  }
  .games_content .block_img_txt.block_img_txt_3 .right_txt {
    padding: 14px 70px 14px 42px;
  }
  .games_content .block_img_txt.block_img_txt_3 .right_txt h4 {
    font-size: 40px;
    line-height: 48px;
    max-height: 100px;
    margin-bottom: 23px;
  }
  .games_content .block_img_txt.block_img_txt_3 .right_txt span {
    font-size: 20px;
  }
  .games_content .block_img_txt.block_img_txt_3 .right_txt .name_author {
    display: block;
    margin-bottom: 1px;
  }
  .games_content .block_img_txt.block_img_txt_3 .right_txt .time_audio {
    margin-top: 6px;
  }
  .games_content .read_more {
    width: 215px;
    line-height: 49px;
    font-size: 20px;
    position: absolute;
    left: 295px;
    top: 147px;
    width: 300px;
    height: 50px;
    top: 242px;
    left: calc(50% - 305px);
    left: -webkit-calc(50% - 305px);
  }
  .games_content .ac-container.ac-collapse {
    margin: 47px 0 42px;
    min-height: 93px;
    width: calc(100% - 20px);
    width: -webkit-calc(100% - 20px);
    margin-left: 10px;
  }
  .games_content .ac-container.ac-collapse #toggle_1 p {
    margin: 0 23px !important;
    font-family: "Life_Rg";
    padding: 31px 0 34px !important;
  }
  .games_content .ac-container.ac-collapse span {
    line-height: 93px;
    font-size: 22px;
    padding-left: 40px;
  }
  .games_content .ac-container.ac-collapse::before {
    top: 27px;
    background-size: 13px;
    right: 45px;
  }
  .games_content .block_img_txt {
    margin: 0 10px 20px;
    width: 48.33185%;
    height: 194px;
    height: 161px;
  }
  .games_content .block_img_txt .left_img {
    width: 161px;
    height: 194px;
  }
  .games_content .block_img_txt .left_img img {
    width: 100%;
  }
  .games_content .block_img_txt .right_txt {
    width: calc(100% - 161px);
    width: -webkit-calc(100% - 161px);
    padding: 24px 21px 15px;
  }
  .games_content .block_img_txt .right_txt h4 {
    font-size: 24px;
    line-height: 30px;
    max-height: 65px;
  }
  .games_content .block_img_txt .right_txt h4 i {
    background-size: 12px;
    padding: 16px;
    position: relative;
    top: 3px;
  }
  .games_content .block_img_txt .right_txt span {
    font-size: 18px;
  }
  .games_content .block_img_txt .left_img {
    height: 161px;
  }
  .games_content .block_img_txt.block_img_txt_3 {
    margin: 0 10px;
  }
  .games_content .block_img_txt.block_img_txt_3 .left_img {
    width: 244px;
    height: 341px;
  }
  .games_content .block_img_txt.block_img_txt_3 .left_img img {
    width: 100%;
    height: 100%;
  }
  .games_content .block_img_txt.block_img_txt_3 .right_txt {
    padding: 14px 70px 14px 42px;
  }
  .games_content .block_img_txt.block_img_txt_3 .right_txt h4 {
    font-size: 40px;
    line-height: 48px;
    max-height: 100px;
    margin-bottom: 23px;
  }
  .games_content .block_img_txt.block_img_txt_3 .right_txt span {
    font-size: 20px;
  }
  .games_content .block_img_txt.block_img_txt_3 .right_txt .name_author {
    display: block;
    margin-bottom: 1px;
  }
  .games_content .block_img_txt.block_img_txt_3 .right_txt .time_audio {
    margin-top: 6px;
  }
  .games_content .block_img_txt.block_img_txt_3 .left_img {
    width: 341px;
  }
  .games_content .block_img_txt.block_img_txt_3 .right_txt {
    width: calc(-241%);
    width: -webkit-calc(-241%);
  }
  .games_content .block_img_txt.block_img_txt_3 .right_txt .time_audio {
    margin-top: 0;
    color: #333;
  }
  .games_content .read_more {
    left: calc(50% - 207px);
    left: -webkit-calc(50% - 207px);
  }
  .games_content .block_img_txt.block_img_txt_3.block_img_txt_4 {
    height: 100%;
  }
  .category_title_tablet {
    display: block;
  }
  /*---------------------------------------search-------------------------------------------*/
  .block_search .search {
    height: 130px;
  }
  .block_search .form-search {
    width: 584px;
  }
  .block_search .form-search .search_input {
    width: 530px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 36px;
  }
  .search_modal h2 {
    font-size: 64px;
    margin: 210px 0 100px;
  }
  .search_modal .close_form {
    right: 75px;
    top: 102px;
    width: 68px;
    height: 68px;
  }
  .search_modal .close_form::before,
  .search_modal .close_form::after {
    left: 31px;
    height: 64px;
  }
  .search_modal .block_queries h3 {
    font-size: 40px;
    letter-spacing: 0;
    margin: 80px 0 20px;
  }
  .search_modal .block_queries .btn_queries_block {
    width: 847px;
    margin: 0 auto;
  }
  .search_modal .form-search {
    width: 584px;
  }
  .search_modal .form-search .search_input {
    width: 530px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 36px;
  }
  .search_result .block_bg_search {
    padding-top: 8px;
  }
  .search_result .block_loading {
    margin: 2px 0 15px;
  }
  .search_result .block_loading span {
    text-decoration: underline;
  }
  .search_result .block_loading .show_more {
    width: 300px;
    height: 50px;
    margin-top: 15px;
  }
  .search_result h3 {
    font-size: 40px;
    margin: 32px auto 28px;
  }
  .search_result .cartoons_content .block_img_txt .left_img.video {
    min-width: 100%;
  }
  .search_result .cartoons_content .block_img_txt .right_txt {
    height: 138px;
    padding: 14px 16px 14px;
  }
  .search_result .cartoons_content .block_img_txt .right_txt h4 {
    font-size: 28px;
    margin-bottom: 8px;
    line-height: 34px;
    max-height: 70px;
  }
  .search_result .cartoons_content .block_img_txt .right_txt h4 i {
    background-size: 11px;
    padding: 15px;
    top: 3px;
    position: relative;
  }
  .search_result .cartoons_content .block_img_txt .right_txt span {
    font-size: 20px;
  }
  .search_result .books_content .block_img_txt {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 9.67187px 20px;
    width: 181px;
    height: 380px;
  }
  .search_result .books_content .block_img_txt .left_img,
  .search_result .books_content .block_img_txt .left_img.book {
    width: 181px;
    height: 218px;
    min-width: 100%;
  }
  .search_result .books_content .block_img_txt .left_img img,
  .search_result .books_content .block_img_txt .left_img.book img {
    width: 181px;
    height: 218px;
  }
  .search_result .books_content .block_img_txt .right_txt {
    width: 100%;
    padding: 8px 16px 8px;
  }
  .search_result .books_content .block_img_txt .right_txt h4 {
    font-size: 28px;
    margin-bottom: 8px;
    line-height: 34px;
    max-height: 105px;
    overflow: hidden;
  }
  .search_result .books_content .block_img_txt .right_txt h4 i {
    background-size: 11px;
    padding: 15px;
    top: 3px;
    position: relative;
  }
  .search_result .books_content .block_img_txt .right_txt span {
    max-height: 42px;
    overflow: hidden;
  }
  .search_result .audiobooks_content .block_img_txt {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 9.67187px 20px;
    width: 181px;
    height: 380px;
  }
  .search_result .audiobooks_content .block_img_txt .left_img,
  .search_result .audiobooks_content .block_img_txt .left_img.book {
    width: 181px;
    height: 218px;
    min-width: 100%;
  }
  .search_result .audiobooks_content .block_img_txt .left_img img,
  .search_result .audiobooks_content .block_img_txt .left_img.book img {
    width: 181px;
    height: 218px;
  }
  .search_result .audiobooks_content .block_img_txt .right_txt {
    width: 100%;
    padding: 8px 16px 8px;
  }
  .search_result .audiobooks_content .block_img_txt .right_txt h4 {
    font-size: 28px;
    margin-bottom: 8px;
    line-height: 34px;
    max-height: 105px;
    overflow: hidden;
  }
  .search_result .audiobooks_content .block_img_txt .right_txt h4 i {
    background-size: 11px;
    padding: 15px;
    top: 3px;
    position: relative;
  }
  .search_result .audiobooks_content .block_img_txt .right_txt span {
    max-height: 42px;
    overflow: hidden;
  }
  .search_result .audiobooks_content .block_img_txt {
    height: 336px;
  }
  .search_result .audiobooks_content .block_img_txt .left_img {
    height: 181px;
  }
  .search_result .audiobooks_content .block_img_txt .left_img img {
    height: 181px;
  }
  .search_result .games_content .block_img_txt {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 9.67187px 20px;
    width: 181px;
    height: 380px;
  }
  .search_result .games_content .block_img_txt .left_img,
  .search_result .games_content .block_img_txt .left_img.book {
    width: 181px;
    height: 218px;
    min-width: 100%;
  }
  .search_result .games_content .block_img_txt .left_img img,
  .search_result .games_content .block_img_txt .left_img.book img {
    width: 181px;
    height: 218px;
  }
  .search_result .games_content .block_img_txt .right_txt {
    width: 100%;
    padding: 8px 16px 8px;
  }
  .search_result .games_content .block_img_txt .right_txt h4 {
    font-size: 28px;
    margin-bottom: 8px;
    line-height: 34px;
    max-height: 105px;
    overflow: hidden;
  }
  .search_result .games_content .block_img_txt .right_txt h4 i {
    background-size: 11px;
    padding: 15px;
    top: 3px;
    position: relative;
  }
  .search_result .games_content .block_img_txt .right_txt span {
    max-height: 42px;
    overflow: hidden;
  }
  .search_result .games_content .block_img_txt {
    height: 336px;
  }
  .search_result .games_content .block_img_txt .left_img {
    height: 181px;
  }
  .search_result .games_content .block_img_txt .left_img img {
    height: 181px;
  }
  .block_404 {
    top: 102px;
  }
}
@media screen and (min-width: 1280px) {
  .slider_1.owl-carousel .owl-nav.disabled,
  .slider_2.owl-carousel .owl-nav.disabled,
  .slider_1.owl-carousel .owl-nav,
  .slider_2.owl-carousel .owl-nav {
    display: block;
  }
}
@media screen and (min-width: 992px) {
  .landing_page {
    max-width: 768px;
    overflow-y: auto;
  }
  .block_img_txt_2 .top_img img {
    height: 210px;
  }
}
.smartbanner {
  position: initial !important;
}
.smartbanner-show {
  margin-top: initial !important;
}
.smartbanner.smartbanner-android {
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  height: 150px;
  padding: 20px;
}
.smartbanner.smartbanner-android .smartbanner-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.smartbanner.smartbanner-android .smartbanner-container .smartbanner-icon {
  width: 85px;
  height: 85px;
}
.smartbanner.smartbanner-android .smartbanner-container .smartbanner-close {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none;
  text-shadow: none;
  font-family: Life_B;
  font-size: 25px;
}
.smartbanner.smartbanner-android .smartbanner-container .smartbanner-info {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  font-size: 18px;
}
.smartbanner.smartbanner-android .smartbanner-container .smartbanner-info * {
  font-family: Life_B;
  font-weight: 500;
  text-shadow: none;
  color: black;
}
.smartbanner.smartbanner-android .smartbanner-container .smartbanner-info .smartbanner-title {
  font-size: 20px;
  line-height: 26px;
}
.smartbanner.smartbanner-android .smartbanner-container .smartbanner-button {
  position: initial !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  height: 10px;
  font-size: 18px;
  line-height: 24px;
  bottom: 20px;
}
.smartbanner.smartbanner-android .smartbanner-container .smartbanner-button span {
  background: -webkit-gradient(linear, left top, left bottom, from(#689f37), to(#689f37));
  background: linear-gradient(to bottom, #689f37, #689f37);
  font-family: Life_B;
  font-weight: 500;
  font-size: 20px;
  color: white;
  padding: 10px;
  border-radius: 4px;
}
